import { useState } from 'react';
import { GoogleMap, LoadScriptNext, Marker, InfoWindow, OverlayView } from '@react-google-maps/api';
import CustomerName from './CustomerName';
import { Loader } from 'rsuite';
import { format, parseISO } from 'date-fns';
import { FORMAT } from 'lib/env';
import { useViewport } from 'shared/ViewportProvider';

const CANCELED = '#ec971f';
const NOT_COMPLETED = '#d9534f';
const COMPLETED = '#5cb85c';

const ViewMap = ({
  jobs,
  handleSummaryShow,
  handleShowDrawer,
  handleWorkOrderSelect
}: any) => {
  const { state } = useViewport();
  const [center, setCenter] = useState({ lat: 51.0447, lng: -114.0719 });
  const status = {
    completed: 'M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z',
    notCompleted: 'M1088 1248v224q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-224q0-26 19-45t45-19h256q26 0 45 19t19 45zm30-1056l-28 768q-1 26-20.5 45t-45.5 19h-256q-26 0-45.5-19t-20.5-45l-28-768q-1-26 17.5-45t44.5-19h320q26 0 44.5 19t17.5 45z',
    canceled: 'M1440 893q0-161-87-295l-754 753q137 89 297 89 111 0 211.5-43.5t173.5-116.5 116-174.5 43-212.5zm-999 299l755-754q-135-91-300-91-148 0-273 73t-198 199-73 274q0 162 89 299zm1223-299q0 157-61 300t-163.5 246-245 164-298.5 61-298.5-61-245-164-163.5-246-61-300 61-299.5 163.5-245.5 245-164 298.5-61 298.5 61 245 164 163.5 245.5 61 299.5z'
  };
  const markerTemplate = [
    '<?xml version="1.0"?>',
    '<svg x="25" y="30" width="26" height="36" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">',
    '<path fill="{{ color }}" stroke="{{ stroke }}" d="M8 2.1c1.1 0 2.2 0.5 3 1.3 0.8 0.9 1.3 1.9 1.3 3.1s-0.5 2.5-1.3 3.3l-3 3.1-3-3.1c-0.8-0.8-1.3-2-1.3-3.3 0-1.2 0.4-2.2 1.3-3.1 0.8-0.8 1.9-1.3 3-1.3z" />' +
    '<path fill="white" transform="scale(0.0035,0.0035) translate(1400,1000)" d="{{ status }}" />' +
    '</svg>'
  ].join('\n');
  const [infoWindowData, setInfoWindowData] = useState<any>(null);
  const [markersLoading, setMarkersLoading] = useState(true);

  const handleJobRefresh = (guid: string) => {
    // TODO: not implemented
  }

  return <div>
    <LoadScriptNext
      id="script-loader"
      googleMapsApiKey={(import.meta as any).env?.VITE_APP_GOOGLE_MAPS_API_KEY || ''}
    >
      <GoogleMap
        id='job-map'
        mapContainerStyle={{
          height: '79vh',
          width: '100%'
        }}
        zoom={11}
        center={center}
        options={{
          controlSize: 26,
          mapTypeId: 'roadmap',
          mapTypeControlOptions: {
            style: 1,
            mapTypeIds: [
              'roadmap',
              'hybrid'
            ]
          }
        }}
      >
        {markersLoading &&
          <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_LAYER}>
            <Loader content="Loading markers..." />
          </OverlayView>
        }

        {infoWindowData &&
          <InfoWindow
            onCloseClick={() => setInfoWindowData(null)}
            position={{
              lat: infoWindowData.customer.lat,
              lng: infoWindowData.customer.lng
            }}
            options={{
              pixelOffset: new (window as any).google.maps.Size(0, -30)
            }}
            onLoad={(e) => {
              setCenter({ lat: e.map.center.lat(), lng: e.map.center.lng() });
            }}
          >
            <div>
              <p>
                <strong>{state?.userIdMap?.[infoWindowData.userId]?.operatorName}</strong>
              </p>
              <CustomerName
                job={infoWindowData}
                handleSummaryShow={handleSummaryShow}
                handleShowDrawer={handleShowDrawer}
                handleWorkOrderSelect={handleWorkOrderSelect}
                handleJobRefresh={handleJobRefresh}
              />

              <div>
                <label>Date</label>
                <div>
                  {format(parseISO(infoWindowData.startDate), FORMAT.DAY_MONTH_DATE)}
                </div>
              </div>
            </div>
          </InfoWindow>
        }

        {jobs.filter((e: any) => e.customer.lat !== null).map((e: any) => {
          let icon = markerTemplate.replace('{{ color }}', state?.userIdMap?.[e.userId]?.color);
          if (e.status === 'completed') {
            icon = icon.replace('{{ stroke }}', COMPLETED).replace('{{ status }}', status.completed);
          } else if (e.status === 'canceled') {
            icon = icon.replace('{{ stroke }}', CANCELED).replace('{{ status }}', status.canceled);
          } else {
            icon = icon.replace('{{ stroke }}', NOT_COMPLETED).replace('{{ status }}', status.notCompleted);
          }

          return <Marker
            position={{
              lat: e.customer.lat,
              lng: e.customer.lng
            }}
            key={e.guid}
            icon={{ url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(icon) }}
            onClick={() => setInfoWindowData(e)}
            onLoad={() => {
              if (markersLoading) {
                setMarkersLoading(false);
              }
            }}
          />
        }
        )}
      </GoogleMap>
    </LoadScriptNext>
  </div>
}

export default ViewMap;
