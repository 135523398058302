import React, { useEffect, useState } from 'react';
import { Panel, DateRangePicker, Table } from 'rsuite';
import { useApolloClient, gql } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { FORMAT, ROLE } from 'lib/env';
import { DrawerFormWrapper, ResponsiveTable } from 'shared';
import startCase from 'lodash/startCase';
import { format, parseISO } from 'date-fns';
import { isMobileOnly } from 'react-device-detect';
import { useQueryString } from 'lib/hooks';
import { flatten, uniq } from 'lodash';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { useViewport } from 'shared/ViewportProvider';
import { getTotal, getTotalHoursWorked } from 'lib/financials';
import { Sales } from './components';

const GET_DAILY_FINANCIALS = gql`
  query dailyFinancials($limit: Int!, $offset: Int!, $where: DailyFinancialsWhere) {
    dailyFinancials(filter: {
      limit: $limit,
      offset: $offset,
      where: $where
    }) {
      edges {
        node {
          guid
          dateOnCalendar
          status
          userId
          royaltyPercentage
          royaltyPaid
          operatorName
          netProfit
          grossRevenue
          totalWagesPaid
          totalExpenses
          salesPerManHour
          createdAt
          labour {
            guid
            employeeName
            employeeType
            startTime
            endTime
            hoursWorked
            hourlyRate
            totalWage
            createdAt
          }
          expenses {
            guid
            expenseType
            itemName
            itemCost
            createdAt
          }
          jobs {
            guid
            startDate
            customerName
            costType
            perCostTypeCost
            workOrderPerCostTypeCost
            timeSpent
            workOrderGuid
          }
        }
      }
    }
  }
`;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const defaultFormValue = {
  labour: [
    {
      guid: uuidv4(),
      employeeName: undefined,
      employeeType: 'contract',
      startTime: undefined,
      endTime: undefined,
      hoursWorked: '',
      hourlyRate: 0,
      totalWage: 0
    }
  ],
  expenses: [
    {
      guid: uuidv4(),
      expenseType: '',
      itemName: '',
      itemCost: ''
    }
  ]
};

const { Column, HeaderCell, Cell } = Table;

interface IDailyFinancialsView {
  show?: boolean,
  userId?: string,
  drawer?: boolean,
  startDate?: Date,
  endDate?: Date,
  guid?: string,
  onHide?: () => void,
}

const DailyFinancialsView = ({ show, userId, drawer, guid, onHide, startDate, endDate }: IDailyFinancialsView) => {
  const { isRole } = usePrairieAuth();
  const { state } = useViewport();
  const client = useApolloClient();
  const query = useQueryString();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startDate || parseISO(query.dateFrom || new Date().toISOString()),
    endDate || parseISO(query.dateTo || new Date().toISOString())
  ]);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dailyFinancials, setDailyFinancials] = useState<any>([]);
  const [expenses, setExpenses] = useState<any[]>([]);
  userId = userId || query.userId;
  guid = guid || query.guid;
  const salesPerManHourTarget = state.companies[0].salesPerManHourTarget;

  useEffect(() => {
    if (startDate && endDate) {
      setDateRange([startDate, endDate]);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (guid) {
      (async function getDailyFinancials() {
        setLoading(true);
        const data: any = await client.query({
          query: GET_DAILY_FINANCIALS,
          variables: {
            offset: 0,
            limit: 100,
            where: {
              AND: [
                { dateOnCalendar: { gte: new Date(new Date(dateRange[0].valueOf()).setHours(0, 0, 0, 0)) } },
                { dateOnCalendar: { lte: new Date(new Date(dateRange[1].valueOf()).setHours(23, 59, 59, 0)) } },
                { userId: { is: userId } }
              ]
            }
          },
          fetchPolicy: 'no-cache'
        });

        setDailyFinancials(data.data.dailyFinancials.edges.node);
        setExpenses(flatten(data.data.dailyFinancials.edges.node.map((d: any) => d.expenses)));
        setLoading(false);

        const workOrderHourlyEstimates = await client.query({
          query: gql`
            query workOrderHourlyEstimates($guid: [ID]) {
              workOrderHourlyEstimates(guid:$guid) {
                edges {
                  node {
                    guid
                    totalCost
                    perCostTypeCost
                    hoursSpent
                    hoursEstimated
                    hoursRemaining
                    monthsDuration
                  }
                }
              }
            }
          `,
          variables: {
            guid: uniq(flatten(data.data.dailyFinancials.edges.node.map((e: any) => e.jobs)).map((j: any) => j.workOrderGuid))
          },
          fetchPolicy: 'no-cache'
        });

        // setHourlyEstimates(workOrderHourlyEstimates.data.workOrderHourlyEstimates?.edges?.node || []);

      })();
    }
  }, [guid, dateRange]);



  const getPercentage = (val: any) => {
    if (isNaN(val) || val === Number.POSITIVE_INFINITY || val === Number.NEGATIVE_INFINITY) {
      return 0;
    }

    return val.toFixed(0);
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      loading={loading}
      show={show}
      title={'View Daily Financials'}
      action={'view'}
      form={`financials/dailies/view/${userId}/${dateRange[0].toISOString()}/${dateRange[1].toISOString()}/${guid}`}
      onHide={onHide}
    />;

  return React.cloneElement(Wrapper, {
    children: <div><Panel className={['content', isMobileOnly || drawer ? 'reset' : ''].join(' ')}>
      <legend>Daily Financials for {dailyFinancials.length > 0 && dailyFinancials[0].operatorName} between {format(dateRange[0], FORMAT.MONTH_DATE)} and {format(dateRange[1], FORMAT.MONTH_DATE)}</legend>
      <div>
        Change Date:<br />
        <DateRangePicker
          cleanable={false}
          format={'MMM dd, yyyy'}
          value={dateRange}
          onChange={(range: any) => setDateRange(range)}
        />
      </div>
      <br />

      <legend><a href={`/app/prod/jobs/list?startDate=${dateRange[0].toISOString()}&endDate=${dateRange[1].toISOString()}&jobStatus=completed`} rel='norel' target='_blank'>Sales</a></legend>
      <Sales 
        jobs={flatten((dailyFinancials || []).map((d: any) => d?.jobs || []))} 
        salesPerManHourTarget={salesPerManHourTarget} 
        loading={loading} 
      />
     
      <br />

      <legend>Labour</legend>
      <ResponsiveTable
        html5
        data={dailyFinancials ? dailyFinancials
          .map((d: any) => d.labour.map((l: any) => ({ ...l, dateOnCalendar: d.dateOnCalendar })))
          .flat()
          .sort((a: any, b: any) => (new Date(a.dateOnCalendar) as any) - (new Date(b.dateOnCalendar) as any)) : []
        }
        autoHeight={true}
        loading={loading}
      >
        <Column flexGrow={1}>
          <HeaderCell>Date Entered</HeaderCell>
          <Cell>{(row: any) => format(parseISO(row.dateOnCalendar), FORMAT.DAY_MONTH_DATE)}</Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Employee Name</HeaderCell>
          <Cell dataKey="employeeName"></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Type</HeaderCell>
          <Cell>
            {(row: any) => {
              return (
                <span>{startCase(row.employeeType)}</span>
              )
            }}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Start</HeaderCell>
          <Cell>
            {(row: any) => {
              return (
                <span>{row.startTime && format(parseISO(row.startTime), FORMAT.DAY_MONTH_TIME)}</span>
              )
            }}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>End</HeaderCell>
          <Cell>
            {(row: any) => {
              return (
                <span>{row.endTime && format(parseISO(row.endTime), FORMAT.DAY_MONTH_TIME)}</span>
              )
            }}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell><div>Hours Worked<br /><strong>Total Hours Worked: {getTotalHoursWorked((dailyFinancials || []).map((d: any) => d.labour).flat())}</strong></div></HeaderCell>
          <Cell dataKey="hoursWorked"></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Rate</HeaderCell>
          <Cell>{(row: any) => <>{formatter.format(row.hourlyRate)}{(row.employeeType || 'contract') === 'salaried' ? '/day' : '/hr'}</>}</Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Total Wage</HeaderCell>
          <Cell>{(row: any) => formatter.format(row.totalWage)}</Cell>
        </Column>
      </ResponsiveTable>


      <br />
      <legend>Expenses</legend>
      <ResponsiveTable
        html5
        data={dailyFinancials ? dailyFinancials.map((d: any) => d.expenses.map((e: any) => ({ ...e, dateOnCalendar: d.dateOnCalendar })))
          .flat()
          .sort((a: any, b: any) => (new Date(a.dateOnCalendar) as any) - (new Date(b.dateOnCalendar) as any)) : []}
        autoHeight={true}
        loading={loading}
      >
        <Column flexGrow={1}>
          <HeaderCell>Date Entered</HeaderCell>
          <Cell>{(row: any) => format(parseISO(row.dateOnCalendar), FORMAT.DAY_MONTH_DATE)}</Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Expense Type</HeaderCell>
          <Cell>
            {(row: any) => {
              return (
                <span>{startCase(row.expenseType)}</span>
              )
            }}
          </Cell>
        </Column>
        <Column flexGrow={2}>
          <HeaderCell>Item Name</HeaderCell>
          <Cell dataKey="itemName"></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Cost</HeaderCell>
          <Cell>{(row: any) => formatter.format(row.itemCost)}</Cell>
        </Column>
      </ResponsiveTable>
      <br />

      <legend>Totals</legend>
      <ResponsiveTable
        html5
        data={
          [
            {
              label: 'Total Sales',
              value: getTotal(dailyFinancials, 'sales')
            },
            {
              label: 'Royalty',
              value: getTotal(dailyFinancials, 'royalty')
            },
            {
              label: 'Labour',
              value: getTotal(dailyFinancials, 'labour')
            },
            {
              label: 'Sales Per Man Hour',
              value: isNaN(getTotal(dailyFinancials, 'sales') / getTotalHoursWorked((dailyFinancials || []).map((d: any) => d.labour).flat()))
                ? 0 : getTotal(dailyFinancials, 'sales') / getTotalHoursWorked((dailyFinancials || []).map((d: any) => d.labour).flat())
            },
            {
              label: 'Operating Expenses',
              value: getTotal(dailyFinancials, 'expenses')
            }
          ].concat(uniq(expenses.map((e: any) => e.expenseType)).map((e: string) => ({
            label: '&nbsp;&nbsp;&nbsp;&nbsp;' + startCase(e),
            value: expenses.filter((c: any) => c.expenseType === e).reduce((p: any, n: any) => p + +n.itemCost, 0)
          })))
            .concat({
              label: 'Gross Net Estimate',
              value: getTotal(dailyFinancials, 'profit')
            })
        }
        autoHeight={true}
        loading={loading}
      >
        <Column flexGrow={1}>
          <HeaderCell>Group</HeaderCell>
          <Cell>{(row: any) => <span dangerouslySetInnerHTML={{ __html: row.label }} />}</Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Value</HeaderCell>
          <Cell>
            {(row: any) => formatter.format(row.value)}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Percentage</HeaderCell>
          <Cell>
            {(row: any) => ['Total Sales', 'Sales Per Man Hour'].includes(row.label) ? '' : getPercentage((row.value / getTotal(dailyFinancials, 'sales') * 100)) + '%'}
          </Cell>
        </Column>
      </ResponsiveTable>
    </Panel>
    </div>
  });
}

export default DailyFinancialsView;
