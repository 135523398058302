import { forwardRef, Fragment, useEffect, useState } from 'react';

import {
  Form,
  Grid,
  Row,
  Col,
  Whisper,
  Tooltip,
  CheckPicker,
  SelectPicker,
  DateRangePicker,
  Input,
  Button,
  DatePicker,
  Checkbox,
  CheckboxGroup,
  InputGroup,
  Message,
} from 'rsuite';

import { FORMAT, getEnv, ROLE } from 'lib/env';
import { CONFIG_APPLICATION_GROUPS, CONFIG_COST_TYPE, CONFIG_STATUS } from 'tenant/config';
import { addMinutes, format, startOfDay, endOfDay, parseISO } from 'date-fns';
import { workOrders as model } from 'lib/validations';
import TextareaAutosize from 'react-autosize-textarea';
import { InputMoney, InputNumber } from 'components/form';
import INTL from 'tenant/intl';
import { fieldTitle, filterFieldsByParentKey } from 'lib/helpers/field';
import { useViewport } from 'shared/ViewportProvider';
import { isMobileOnly } from 'react-device-detect';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import store from 'lib/storage';
import { IS_ASSINIBOINE, IS_BUGABOO } from 'lib/tenant';
import { flatten } from 'lodash';
import { MdAdd, MdCopyAll, MdDelete, MdHelpOutline } from 'react-icons/md';
import { Textarea } from 'components/rsuite';

interface IWorkOrderFormFields {
  container: any,
  drawer: boolean | undefined,
  formValue: any,
  formIndex: string,
  applicationGroup: string,
  workOrderBookAs: string,
  onJobAdd: () => void,
  onJobRemove: (val: any) => void,
  onJobDuplicate: (val: any) => void,
  onJobChange: (guid: string, job: string) => void,
  onJobUpdate: (id: string, data: any) => void,
  handleSetRef: (index: string, ref: any) => void
}

const getCostLabel = (value: string) => {
  switch (value) {
    case 'per_hour':
      return 'Per Hour';
    default:
      return 'Per Visit';
  }
}

const getTotalCostLabel = (value: string) => {
  switch (value) {
    case 'per_hour':
      return 'Total Cost (Per Visit)';
    //   case 'per_week':
    //     return 'Total Cost (Weekly)';
    //   case 'per_month':
    //     return 'Total Cost (Per Visit)'
    default:
      return 'Total Cost';
  }
}

let startTime: Date = new Date(new Date().setHours(0, 0, 0));
let endTime: Date = new Date(new Date().setHours(24, 0, 0));
const times: any = [];
let timePickerData: any = [];

while (timePickerData.length < 48) {
  if (timePickerData.length === 0) {
    timePickerData.push({
      date: new Date(new Date().setHours(0, 0, 0, 0)),
      value: format(new Date(new Date().setHours(0, 0, 0, 0)), 'h:mmaaa'),
      label: format(new Date(new Date().setHours(0, 0, 0, 0)), 'h:mmaaa'),
    });
  } else {
    timePickerData.push({
      date: addMinutes(timePickerData[timePickerData.length - 1].date, 30),
      value: format(addMinutes(timePickerData[timePickerData.length - 1].date, 30), 'h:mmaaa'),
      label: format(addMinutes(timePickerData[timePickerData.length - 1].date, 30), 'h:mmaaa')
    });
  }
}

while (startTime < endTime) {
  times.push(startTime);
  startTime = new Date(addMinutes(startTime, 30));
}

const WorkOrderFields = ({
  container,
  drawer,
  formValue,
  formIndex,
  applicationGroup,
  workOrderBookAs,
  onJobAdd,
  onJobRemove,
  onJobDuplicate,
  handleSetRef,
  onJobChange,
  onJobUpdate
}: IWorkOrderFormFields) => {
  const { isRole } = usePrairieAuth();
  const { state } = useViewport();
  const workTypes = filterFieldsByParentKey(state.fields, 'work_types');
  const [defaultFormValue, setDefaultFormValue] = useState(formValue);
  const [defaultFormError, setDefaultFormError] = useState({});
  const [services, setServices] = useState<string[]>([]);
  const [deicerLineItem, setDeicerLineItem] = useState<{
    status: string | null,
    includedUpToPerMonth: number | null,
    includedUpToPerSeason: number | null,
    pricePerKg: number | null
  }>(formValue.deicerLineItem);
  const [gravelLineItem, setGravelLineItem] = useState<{
    status: string | null,
    includedUpToPerMonth: number | null,
    includedUpToPerSeason: number | null,
    pricePerKg: number | null
  }>(formValue.gravelLineItem);

  const jobStatuses = (state.fields || []).filter((f: any) => f.parentKey === 'job_statuses').map((f: any) => ({ label: f.title, value: f.key, color: f.color }));
  let defaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === applicationGroup);

  if (!defaultGroup) {
    defaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === 'maintenance');
  }

  useEffect(() => {
    store.set('default-work-group', formValue.applicationGroup);
  }, [formValue.applicationGroup]);

  // useEffect(() => {
  //   console.log(formValue.deicerLineItem);
  // }, [formValue.deicerLineItem])

  useEffect(() => {
    onJobUpdate(formIndex, defaultFormValue);

    // updated selected services
    let selectedServices: any = [];

    if (defaultFormValue?.workType) {
      const defaultFormValueWorkType = typeof (defaultFormValue?.workType) === 'string' ? [defaultFormValue?.workType] : defaultFormValue?.workType;

      defaultFormValueWorkType.forEach((item: any) => {
        selectedServices.push(item);
        const field = state.fields.find((f: any) => f.key === item);
        const includes = state.fields.filter((f: any) => (field?.defaults || []).includes(f.id)).map((f: any) => f.key);
        selectedServices.push(includes);
      });

      if (defaultFormValue?.deicerLineItem && defaultFormValue?.deicerLineItem?.status !== null) {
        setDeicerLineItem(defaultFormValue.deicerLineItem);
      }

      selectedServices = flatten(selectedServices);
      setServices(selectedServices);
    }

    if (!IS_ASSINIBOINE && (!defaultFormValue?.deicerLineItem || defaultFormValue?.deicerLineItem?.status === null)) {
      if (flatten(selectedServices).filter((k: any) => k.includes('deicer') || k.includes('icescraping__a943fd24__1640738746.86085')).length > 0 && deicerLineItem?.status === null) {
        setDeicerLineItem({ ...deicerLineItem, status: 'deicerAtCost', pricePerKg: 2.75 });
      }

      if (!IS_ASSINIBOINE && (flatten(selectedServices).filter((k: any) => k.includes('gravel')).length > 0 && deicerLineItem?.status === null)) {
        setGravelLineItem({ ...gravelLineItem, status: 'gravelAtCost', pricePerKg: 2.0 });
      }
    }

    setServices(flatten(selectedServices));
  }, [defaultFormValue]);

  useEffect(() => {
    onJobUpdate(formIndex, { ...defaultFormValue, deicerLineItem });
  }, [deicerLineItem]);

  const getRecurrence = () => {
    if (!IS_ASSINIBOINE) {
      return [
        { label: 'One Time - Variable', value: 'onetime' },
        // { label: 'One Time - Fixed', value: 'onetime_fixed' },
        { label: 'Weekly', value: 'weekly' },
        { label: 'Bi-Weekly', value: 'biweekly' },
        { label: 'On-Demand', value: 'ondemand' }
      ];
    }

    return [
      { label: 'One Time - Variable', value: 'onetime' },
      { label: 'One Time - Fixed', value: 'onetime_fixed' },
      { label: 'Weekly', value: 'weekly' },
      { label: 'Bi-Weekly', value: 'biweekly' },
      { label: 'On-Demand', value: 'ondemand' }
    ];
  }

  const getCostType = () => {
    let costType = [...CONFIG_COST_TYPE];

    if (applicationGroup === 'arborcare__242153b6__1644606571') {
      costType = costType.concat({ label: 'Per Tree/Shrub', value: 'per_tree_shrub' });
    }

    if (applicationGroup === 'irrigation__d9d78aaa__1680553175') {
      costType = costType.concat({ label: 'Per Device', value: 'per_device' });
    }

    return costType;
  }

  return (
    <Form
      ref={(ref: any) => handleSetRef(formIndex, ref)}
      onChange={setDefaultFormValue}
      onCheck={setDefaultFormError}
      formError={defaultFormError}
      formValue={defaultFormValue}
      model={model[applicationGroup] || model.default}
    >
      <input type="hidden" value={formValue.guid || ''} />

      <Grid fluid>
        {getEnv() === 'dev' &&
          <Row className="mb-12">
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>UUID:</Form.ControlLabel>
                <p>{defaultFormValue?.guid}</p>
              </Form.Group>
            </Col>
          </Row>
        }
        
        {formValue.status === 'completed' &&
          <Row>
            <Col xs={24}>
              <Message type='error' className='mb-24'>
                <strong>DANGER,</strong> this work order is marked as completed. Do not edit this work order to make a new one.
                <p>Use <strong>Repeat</strong> functionality from drop down menu to create new work order.</p>
              </Message>
            </Col>
          </Row>
        }


        <Row>
          {!['construction', 'christmas_lights'].includes(applicationGroup) &&
            <Col md={7} xs={24}>
              {/* Services */}
              <label className="required">{INTL.WORK_TYPE}:</label>
              <div>
                <Whisper placement="top" trigger="hover"
                  speaker={<Tooltip>{defaultFormValue.workType && defaultFormValue.workType.length > 0
                    ? fieldTitle(defaultFormValue.workType, state.fields).join(', ')
                    : 'Nothing selected'}
                  </Tooltip>}>
                  <Form.Control
                    container={() => container && container.current}
                    name="workType"
                    accepter={defaultGroup.accepter || CheckPicker}
                    block
                    data={workTypes.filter((o: any) => o.applicationGroup === applicationGroup && o.visibleOnBooking)
                      .map((o: any) => ({
                        value: o.key,
                        label: o.title + ' ' + ((o.defaults || []).length > 0 ? '(+' + (o.defaults || []).length + ')' : '')
                      }))
                    }
                    renderMenuItem={(label: any, item: any) => {
                      const field = state.fields.find((f: any) => f.key === item.value);
                      const includes = state.fields.filter((f: any) => (field?.defaults || []).includes(f.id));

                      if (!field || includes.length === 0) {
                        return <div>{label}</div>
                      }

                      return <div>
                        <div>{field.title}</div>
                        <div style={{ fontSize: '10px' }}>({includes.map((i: any) => i.title).join(', ')})</div>
                      </div>;
                    }}
                    onChange={(val: any) => {
                      let selected: string | undefined = undefined;

                      if (Array.isArray(val)) {
                        if (val.length > 0) {
                          selected = val[val.length - 1];
                        }
                      } else {
                        selected = val;
                      }

                      if (selected) {
                        const field = state.fields.find((f: any) => f.key === selected);
                        if (field.startDate) {
                          setDefaultFormValue({
                            ...defaultFormValue,
                            workType: val,
                            startEndDate: [parseISO(field.startDate), parseISO(field.endDate || field.startDate)]
                          })
                        }
                      }
                    }} />
                </Whisper>
              </div>

              {workTypes.find((w: any) => w.allowFreeEntry && defaultFormValue.workType.includes(w.key)) &&
                <div className="mt-12">
                  <label>Other:</label>
                  <Form.Control name="otherServices" accepter={Input} style={{ width: '100%' }} />
                </div>
              }
            </Col>
          }

          {['christmas_lights'].includes(applicationGroup) &&
            <Col md={3} xs={24}>
              <label className="required">{INTL.WORK_TYPE}:</label>
              <div>
                <Form.Control
                  name="workType"
                  accepter={SelectPicker}
                  container={() => container && container.current}
                  block
                  cleanable={false}
                  searchable={false}
                  data={[
                    { value: 'setup', label: 'Setup' },
                    { value: 'gemstone', label: 'Gemstone' },
                    { value: 'takedown', label: 'Takedown' },
                    { value: 'fixup', label: 'Fixup' },
                  ]}
                  onChange={(val: any) => {
                    if (val === 'takedown') {
                      // only increment if date is past october
                      const yearIncrement = new Date() < new Date(new Date().getFullYear(), 10, 1) ? 0 : 1;
                      setDefaultFormValue({
                        ...defaultFormValue,
                        workType: val,
                        startEndDate: [new Date(new Date().getFullYear() + yearIncrement, 0, 1), new Date(new Date().getFullYear() + yearIncrement, 0, 1)]
                      });
                    } else {
                      setDefaultFormValue({ ...defaultFormValue, workType: val });
                    }
                    onJobChange(formValue.guid, val);
                  }} />
              </div>
            </Col>
          }

          {/**
          * Start/End Date
          */}
          {defaultGroup.fields.includes('startEndDate') &&
            <Col md={5} xs={24}>
              <label className="required">Start/End Date:</label>
              <div>
                <Form.Control
                  name="startEndDate"
                  container={() => container && container.current}
                  accepter={DateRangePicker}
                  cleanable={false}
                  block
                  showOneCalendar={isMobileOnly}
                  ranges={[
                    {
                      label: 'today',
                      value: [startOfDay(new Date()), endOfDay(new Date())]
                    }
                  ]}
                  renderValue={(date: any) => `${format(date[0], FORMAT.DAY_MONTH_DATE)} ~ ${format(date[1], FORMAT.DAY_MONTH_DATE)}`}
                  value={[new Date(defaultFormValue.startEndDate[0]), new Date(defaultFormValue.startEndDate[1])] as any} />
              </div>

              {['christmas_lights'].includes(applicationGroup) &&
                <div className="mt-5">
                  <label>Time:</label>
                  <Form.Control
                    name="startTime"
                    container={() => container && container.current}
                    accepter={DatePicker}
                    cleanable={true}
                    block
                    format={'HH:mm'}
                    ranges={[]}
                    disabledMinutes={(minute: any) => ![0, 15, 30, 45].includes(minute)}
                    hideMinutes={(minute: any) => ![0, 15, 30, 45].includes(minute)}
                    onOpen={() => {
                      if ((defaultFormValue.startTime || '').length === 0) {
                        setDefaultFormValue({ ...defaultFormValue, startTime: new Date('1999-01-01T16:00:00.000Z') });
                      }
                    }}
                    onClean={() => {
                      setDefaultFormValue({ ...defaultFormValue, startTime: null })
                    }} />
                </div>
              }
            </Col>
          }

          {defaultGroup.fields.includes('endTime') &&
            <Fragment>
              <Col md={2} lg={2} xs={24}>
                <label className="required">End Time:</label>
                <Form.Control
                  name="startEndDate"
                  accepter={SelectPicker}
                  searchable={false}
                  cleanable={false}
                  container={() => container && container.current}
                  block
                  data={timePickerData}
                  value={format(defaultFormValue.startEndDate[1], 'h:mmaaa')}
                  onChange={(value: any) => {
                    let date = timePickerData.find((t: any) => t.value === value).date;
                    setDefaultFormValue({
                      ...defaultFormValue,
                      startEndDate: [
                        defaultFormValue.startEndDate[0],
                        new Date(new Date(+defaultFormValue.startEndDate[1]).setHours(date.getHours(), date.getMinutes(), 0, 0))
                      ]
                    });
                  }} />
              </Col>
              <Col md={3}>
                <label className="required">Date:</label>
                <Form.Control
                  name="endDate"
                  accepter={DatePicker}
                  container={() => container && container.current}
                  cleanable={false}
                  oneTap
                  block
                  format={'MMM d, yyyy'}
                  ranges={[]}
                  value={defaultFormValue.startEndDate[1]}
                  onChange={(val: any) => setDefaultFormValue({
                    ...defaultFormValue,
                    modifiedStartEndDate: new Date().toISOString(),
                    startEndDate: [defaultFormValue.startEndDate[0], val]
                  })} />
              </Col>
            </Fragment>
          }

          {(['snow_removal'].includes(applicationGroup) && defaultGroup.fields.includes('contractType') && IS_ASSINIBOINE) &&
            <>
              <Col md={3} xs={24}>
                <label>Contract Type:</label>
                <div>
                  <Form.Control
                    name="contractType"
                    accepter={SelectPicker}
                    container={() => container && container.current}
                    block
                    searchable={false}
                    cleanable={false}
                    data={[
                      { label: 'Residential', value: 'residential' },
                      { label: 'Vacation', value: 'vacation' },
                      { label: 'Per Removal', value: 'per_removal' },
                      { label: 'Commercial', value: 'commercial' },
                      { label: 'Commercial Seasonal', value: 'commercial_seasonal' },
                      { label: 'Commercial Package', value: 'commercial_package' },
                      { label: 'Hourly', value: 'hourly' }
                    ]} />
                </div>
              </Col>
              <Col md={3}>
                <label>
                  {(() => {
                    switch (defaultFormValue.contractType) {
                      case 'residential': return <Fragment>Monthly Rate:</Fragment>;
                      case 'vacation': return <Fragment>Vacation Rate:</Fragment>;
                      case 'per_removal': return <Fragment>Per Removal Rate:</Fragment>;
                      case 'commercial': return <Fragment>Monthly Rate:</Fragment>;
                      case 'commercial_seasonal': return <Fragment>Seasonal Rate:</Fragment>;
                      case 'commercial_package': return <Fragment>Monthly Rate:</Fragment>;
                      case 'hourly': return <Fragment>Hourly Rate:</Fragment>;
                      default: return <Fragment>Rate:</Fragment>;
                    }
                  })()}
                </label>
                <div>
                  <Form.Control name="perCostTypeCost" accepter={InputNumber} style={{ width: '100%' }} />
                </div>
              </Col>
              {!['per_removal'].includes(defaultFormValue.contractType) &&
                <Fragment>
                  <Col md={2}>
                    <label>Visits Incl:</label>
                    <div>
                      <Form.Control name="visitsIncluded" accepter={InputNumber} style={{ width: '100%' }} />
                    </div>
                  </Col>
                  <Col md={2}>
                    <label>Rate Above:</label>
                    <div>
                      <Form.Control name="rateAboveIncluded" accepter={InputNumber} style={{ width: '100%' }} />
                    </div>
                  </Col>
                </Fragment>
              }
              <Col md={2}>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip>Deicer priced at $X per kg</Tooltip>}>
                  <label>Deicer Rate: <MdHelpOutline /></label>
                </Whisper>
                <Form.Control name="deicerRate" accepter={InputNumber} style={{ width: '100%' }} />
              </Col>
            </>
          }


          {/**
         * Repeat
         */}
          {defaultGroup.fields.includes('repeatPeriod') &&
            <Col md={4}>
              <Whisper placement="top" trigger="hover" speaker={<Tooltip>
                <p><strong>One Time - Variable</strong>: job, work order, invoice dates updated on completion</p>
                <p><strong>One Time - Fixed</strong>: no date updates</p>
              </Tooltip>}>
                <Form.ControlLabel>Recurrence: <MdHelpOutline /></Form.ControlLabel>
              </Whisper>
              <div>
                <Form.Control
                  name="recurrence"
                  accepter={SelectPicker}
                  block
                  container={() => container && container.current}
                  searchable={false}
                  cleanable={false}
                  data={getRecurrence()} />
              </div>

              {(defaultFormValue && ['weekly', 'biweekly'].includes(defaultFormValue.recurrence)) &&
                <div className="mt-5">
                  <label>Repeat On:</label>
                  <div>
                    <Form.Control
                      name="daysWorking"
                      accepter={CheckPicker}
                      block
                      container={() => container && container.current}
                      searchable={false}
                      cleanable={false}
                      data={[
                        { label: 'Mon', value: 'mon' },
                        { label: 'Tue', value: 'tue' },
                        { label: 'Wed', value: 'wed' },
                        { label: 'Thu', value: 'thu' },
                        { label: 'Fri', value: 'fri' },
                        { label: 'Sat', value: 'sat' },
                        { label: 'Sun', value: 'sun' }
                      ]} />
                  </div>
                </div>
              }

              {((defaultFormValue && ['ondemand'].includes(defaultFormValue.recurrence))) &&
                <div className="mt-5">
                  <label>Number Of Visits:</label>
                  <div>
                    <Form.Control name="visitsIncluded" accepter={InputNumber} style={{ width: '100%' }} />
                  </div>
                </div>
              }
            </Col>
          }

          {defaultGroup.fields.includes('costType') &&
            <Col md={4}>
              <label>Cost Type:</label>
              <div>
                <Form.Control
                  name="costType"
                  accepter={SelectPicker}
                  container={() => container && container.current}
                  block
                  searchable={false}
                  data={getCostType()} />
              </div>

              <div className="mt-5">
                <Whisper placement="top" trigger="hover" speaker={<Tooltip>{getCostLabel(defaultFormValue.costType)} cost will display on crew lists</Tooltip>}>
                  <Form.ControlLabel>{getCostLabel(defaultFormValue.costType)}: <MdHelpOutline /></Form.ControlLabel>
                </Whisper>
                <Form.Control name="perCostTypeCost" accepter={InputNumber} style={{ width: '100%' }} />
              </div>

              <div className="mt-5">
                <Whisper placement="top" trigger="hover" speaker={<Tooltip>Total cost charged monthly or weekly</Tooltip>}>
                  <Form.ControlLabel>{getTotalCostLabel(defaultFormValue.costType)}: <MdHelpOutline /></Form.ControlLabel>
                </Whisper>
                <Form.Control name="totalCost" accepter={InputNumber} style={{ width: '100%' }} />
              </div>
            </Col>
          }


          {/**
         * Snow Removal
         */}
          {['christmas_lights'].includes(applicationGroup) &&
            <Fragment>
              {['setup', 'takedown', 'fixup'].includes(defaultFormValue.workType) &&
                <Col md={3}>
                  {['setup'].includes(defaultFormValue.workType) &&
                    <div className="mb-5">
                      <label className={defaultGroup.required.includes('numberOfStrands') ? 'required' : ''}>Number Of Strands:</label>
                      <div>
                        <Form.Control name="numberOfStrands" accepter={Input} style={{ width: '100%' }} />
                      </div>
                    </div>
                  }

                  <div className="mb-5">
                    <label className={defaultGroup.required.includes('strandOwnership') ? 'required' : ''}>Strand Ownership:</label>
                    <div>
                      <Form.Control
                        name="strandOwnership"
                        accepter={SelectPicker}
                        container={() => container && container.current}
                        block
                        searchable={false}
                        data={[
                          { label: 'Rent', value: 'rent' },
                          { label: 'Buy', value: 'buy' },
                          { label: 'Own', value: 'own' },
                          { label: 'Replacements', value: 'replacements' }
                        ]} />
                    </div>
                  </div>
                </Col>
              }

              {['setup'].includes(defaultFormValue.workType) &&
                <Col md={3}>
                  <label className={defaultGroup.required.includes('numberOfTrees') ? 'required' : ''}>Number Of Trees:</label>
                  <div>
                    <Form.Control name="numberOfTrees" accepter={Input} style={{ width: '100%' }} />
                  </div>

                  <div className="mt-5">
                    <label>Tree Type:</label>
                    <div>
                      <Form.Control
                        name="treeTypes"
                        accepter={SelectPicker}
                        container={() => container && container.current}
                        block
                        searchable={false}
                        data={[
                          { label: 'Small', value: 'Small' },
                          { label: 'Medium', value: 'Medium' },
                          { label: 'Large', value: 'Large ' }
                        ]} />
                    </div>
                  </div>
                </Col>
              }

              {['setup'].includes(defaultFormValue.workType) &&
                <Col md={3}>
                  <Whisper placement="top" trigger="hover" speaker={<Tooltip>Number of storeys on the property</Tooltip>}>
                    <label>House Type: <MdHelpOutline /></label>
                  </Whisper>
                  <div>
                    <Form.Control
                      name="houseType"
                      accepter={CheckPicker}
                      block
                      container={() => container && container.current}
                      searchable={false}
                      cleanable={false}
                      data={[
                        { label: '1st', value: '1st' },
                        { label: '2nd', value: '2nd' },
                        { label: '3rd', value: '3rd' }
                      ]} />
                  </div>
                </Col>
              }

              <Col md={3}>
                <label className={applicationGroup === 'christmas_lights' && ['setup'].includes(defaultFormValue.workType) ? 'required' : ''}>Total Cost: </label>
                <div>
                  <Form.Control name="perCostTypeCost" accepter={InputNumber} style={{ width: '100%' }} />
                </div>
              </Col>
            </Fragment>
          }

          {['construction', 'roofing__59634106__1710128534'].includes(applicationGroup) &&
            <>
              <Col md={4}>
                <label>
                  Payout Percentage: <Form.HelpText className='mt-0' tooltip>Leave empty to use <strong>Payout Percentage</strong> assigned to user selected.</Form.HelpText>
                </label>
                <div>
                  <Form.Control name="payoutPercentage" accepter={InputNumber} style={{ width: '100%' }} />
                </div>
              </Col>
              <Col md={4}>
                <label>Cost:</label>
                <div>
                  <Form.Control name="totalCost" accepter={InputNumber} style={{ width: '100%' }} />
                </div>
              </Col>
            </>
          }


          {(['snow_removal'].includes(applicationGroup) && !IS_ASSINIBOINE) &&
            <Col md={3} xs={24}>
              <label>Contract Type:</label>
              <div>
                <Form.Control
                  name="contractType"
                  accepter={SelectPicker}
                  container={() => container && container.current}
                  block
                  searchable={false}
                  cleanable={false}
                  data={[
                    { label: 'Unlimited Visits', value: 'unlimited_visits' },
                    { label: 'Visits Per Month', value: 'visits_per_month' },
                    { label: 'Visits Per Season', value: 'visits_per_season' },
                  ]} />
              </div>

              {defaultFormValue.contractType !== 'unlimited_visits' &&
                <>
                  <div className='mt-6'>
                    <label className='mb-3'>Visits Included:</label>
                    <div>
                      <Form.Control name="visitsIncluded" accepter={InputNumber} style={{ width: '100%' }} />
                    </div>
                  </div>
                </>
              }

              {defaultFormValue.contractType !== 'unlimited_visits' &&
                <>
                  <div className='mt-5'>
                    <label className='mb-0'>
                      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Services counted against visits per site</Tooltip>}>
                        <Form.ControlLabel>Services In Visit: <MdHelpOutline /></Form.ControlLabel>
                      </Whisper>
                    </label>
                    <div>
                      <Form.Control
                        container={() => container && container.current}
                        name="servicesInVisit"
                        accepter={CheckPicker}
                        style={{ width: '100%' }}
                        data={
                          flatten(
                            workTypes
                              .filter((o: any) => (defaultFormValue?.workType || []).includes(o.key) && o.visibleOnBooking)
                              .map((d: any) => flatten(workTypes.filter((w: any) => (d.defaults || []).includes(w.id))))
                          ).map((o: any) => ({ value: o.key, label: o.title }))
                        }
                      />
                    </div>
                  </div>
                  <div className='mt-5'>
                    <label className='mb-0'>
                      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Cost per visit used when number of visits is greater than visits included</Tooltip>}>
                        <Form.ControlLabel>Cost Per Visit (Over Included): <MdHelpOutline /></Form.ControlLabel>
                      </Whisper>
                    </label>
                    <div>
                      <Form.Control
                        name="rateAboveIncluded"
                        accepter={InputNumber}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>
                </>
              }
            </Col>
          }

          {defaultGroup.fields.includes('crew') &&
            <Col md={4}>
              <div className="mb-6">
                <label>User:</label>
                <div>
                  <Form.Control
                    name="userId"
                    accepter={SelectPicker}
                    container={() => container && container.current}
                    block
                    placeholder="Assign later"
                    groupBy="role"
                    data={state.users.filter((u: any) => (u.workGroup || []).includes(applicationGroup))} />
                </div>
              </div>
              {applicationGroup === 'christmas_lights' &&
                <div>
                  <label className="mb-0">Preferred:</label>
                  <Form.Control
                    name="preferredCrew"
                    accepter={Checkbox}
                    checked={formValue.preferredCrew}
                    onClick={() => {
                      setDefaultFormValue({ ...defaultFormValue, preferredCrew: !formValue.preferredCrew });
                    }}
                    > Preferred Crew
                  </Form.Control>
                </div>
              }
            </Col>
          }
        </Row>

        {(applicationGroup === 'snow_removal' && !IS_ASSINIBOINE) &&
          <>
            {(services.includes('deicer') || services.includes('icescraping__a943fd24__1640738746.86085')) &&
              <Row className="mb-10">
                <>
                  <Col md={4} xs={12}>
                    <label>Deicer:</label>
                    <SelectPicker
                      block
                      value={deicerLineItem?.status}
                      container={() => container && container.current}
                      cleanable
                      searchable={false}
                      data={[
                        { value: 'included', label: 'Deicer included' },
                        { value: 'includedUpToPerMonth', label: 'Deicer included up to x per month' },
                        { value: 'includedUpToPerSeason', label: 'Deicer included up to x per season' },
                        { value: 'deicerAtCost', label: 'Deicer At Cost' },
                        { value: 'ecoTractionAtCost', label: 'Eco-Traction At Cost' }
                      ]}
                      onChange={(val: any) => setDeicerLineItem({ ...deicerLineItem, status: val })}
                    />
                  </Col>
                  {['includedUpToPerMonth', 'includedUpToPerSeason'].includes(deicerLineItem?.status || '') &&
                    <Col md={3} xs={12}>
                      <label>Amount Included (kg):</label>
                      {deicerLineItem.status === 'includedUpToPerMonth' &&
                        <InputNumber
                          value={deicerLineItem.includedUpToPerMonth === null ? undefined : (deicerLineItem?.includedUpToPerMonth || '').toString()}
                          onChange={(val: any) => setDeicerLineItem({ ...deicerLineItem, includedUpToPerMonth: isNaN(parseFloat(val)) ? null : parseFloat(val), includedUpToPerSeason: null })}
                        />
                      }
                      {deicerLineItem.status === 'includedUpToPerSeason' &&
                        <InputNumber
                          value={deicerLineItem.includedUpToPerSeason === null ? undefined : (deicerLineItem?.includedUpToPerSeason || '').toString()}
                          onChange={(val: any) => setDeicerLineItem({ ...deicerLineItem, includedUpToPerMonth: null, includedUpToPerSeason: isNaN(parseFloat(val)) ? null : parseFloat(val) })}
                        />
                      }
                    </Col>
                  }

                  {['deicerAtCost', 'ecoTractionAtCost'].includes(deicerLineItem?.status || '') &&
                    <Col md={3} xs={12}>
                      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Deicer priced at $X per kg</Tooltip>}>
                        <label>Deicer Rate: <MdHelpOutline /></label>
                      </Whisper>
                      <InputGroup>
                        <InputNumber
                          onChange={(val: any) => {
                            setDeicerLineItem({ ...deicerLineItem, pricePerKg: isNaN(parseFloat(val)) ? null : parseFloat(val) });
                          }}
                          value={deicerLineItem.pricePerKg?.toString()}
                          style={{ width: '100%' }} />
                        <InputGroup.Addon>
                          /kg
                        </InputGroup.Addon>
                      </InputGroup>
                    </Col>
                  }
                </>
              </Row>
            }

            {services.includes('icemelt_gravel bin') &&
              <Row className="mb-10">
                <>
                  <Col md={4} xs={12}>
                    <label>Gravel:</label>
                    <SelectPicker
                      block
                      value={gravelLineItem?.status}
                      container={() => container && container.current}
                      cleanable
                      searchable={false}
                      data={[
                        { value: 'included', label: 'Gravel included' },
                        { value: 'includedUpToPerMonth', label: 'Gravel included up to x per month' },
                        { value: 'includedUpToPerSeason', label: 'Gravel included up to x per season' },
                        { value: 'gravelAtCost', label: 'Gravel At Cost' }
                      ]}
                      onChange={(val: any) => setGravelLineItem({ ...gravelLineItem, status: val })}
                    />
                  </Col>
                  {['includedUpToPerMonth', 'includedUpToPerSeason'].includes(gravelLineItem?.status || '') &&
                    <Col md={3} xs={12}>
                      <label>Amount Included (kg):</label>
                      {gravelLineItem.status === 'includedUpToPerMonth' &&
                        <InputNumber
                          value={deicerLineItem.includedUpToPerMonth === null ? undefined : deicerLineItem.includedUpToPerMonth.toString()}
                          onChange={(val: any) => setDeicerLineItem({ ...deicerLineItem, includedUpToPerMonth: isNaN(parseFloat(val)) ? null : parseFloat(val), includedUpToPerSeason: null })}
                        />
                      }
                      {gravelLineItem.status === 'includedUpToPerSeason' &&
                        <InputNumber
                          value={deicerLineItem.includedUpToPerSeason === null ? undefined : deicerLineItem.includedUpToPerSeason.toString()}
                          onChange={(val: any) => setDeicerLineItem({ ...deicerLineItem, includedUpToPerMonth: null, includedUpToPerSeason: isNaN(parseFloat(val)) ? null : parseFloat(val) })}
                        />
                      }
                    </Col>
                  }

                  {['gravelAtCost'].includes(gravelLineItem?.status || '') &&
                    <Col md={3} xs={12}>
                      <Whisper placement="top" trigger="hover" speaker={<Tooltip>Deicer priced at $X per kg</Tooltip>}>
                        <label>Gravel Rate: <MdHelpOutline /></label>
                      </Whisper>
                      <InputGroup>
                        <Form.Control
                          name="gravelRate"
                          value={gravelLineItem?.pricePerKg}
                          accepter={Input}
                          onChange={(val: any) => {
                            setGravelLineItem({ ...gravelLineItem, pricePerKg: isNaN(parseFloat(val)) ? null : parseFloat(val) });
                          }}
                          style={{ width: '100%' }} />
                        <InputGroup.Addon>
                          /kg
                        </InputGroup.Addon>
                      </InputGroup>
                    </Col>
                  }
                </>
              </Row>}
          </>
        }

        <Row style={{ marginTop: 5 }}>
          {(applicationGroup === 'christmas_lights' && ['setup', 'fixup'].includes(defaultFormValue.workType)) &&
            <Col md={24}>
              <div className="mb-5">
                <label className={defaultGroup.required.includes('typeOfLights') ? 'required' : ''}>Type Of Lights:</label>
                <div>
                  <Form.Control name="typeOfLights" accepter={Input} style={{ width: '100%' }} />
                </div>
              </div>
            </Col>
          }

          {!isRole(ROLE.CLIENT, ROLE.CLEANER) &&
            <Col md={12} sm={24} className="mt-5">
              <label>Notes / Work Comments:</label>
              <div>
                <Form.Control name={'workDescription'} accepter={Textarea} rows={2} />
              </div>
            </Col>
          }
        </Row>

        {
          defaultGroup.fields.includes('timeInstructions') &&
          <Row className="mt-10">
            <Col md={12} sm={24}>
              <label>Time Instructions:</label>
              <div>
                <Form.Control name="timeInstructions" accepter={Input} style={{ width: '100%' }} />
              </div>
            </Col>
          </Row>
        }

        <Row className='mt-10'>
          <Col md={12} sm={24}>
            <label>Special Instructions:</label>
            <div>
              <Checkbox value={'true'} checked={!!defaultFormValue?.specialInstructions?.callOnYourWay} onChange={() => {
                const newDefaultFormValue = { ...defaultFormValue };
                newDefaultFormValue.specialInstructions = { ...defaultFormValue.specialInstructions, callOnYourWay: !(defaultFormValue.specialInstructions?.callOnYourWay || false) };
                setDefaultFormValue(newDefaultFormValue);
              }}>Call On Your Way</Checkbox>
              <Checkbox style={{ display: 'none' }} />
            </div>
            {(IS_BUGABOO && applicationGroup === 'maintenance') &&
              <div>
                <Checkbox checked={defaultFormValue?.customField_irrigationServices} onChange={(event: any, val: any) => {
                  setDefaultFormValue({ ...formValue, customField_irrigationServices: val })
                }}>Irrigation Services</Checkbox>
              </div>
            }
          </Col>
        </Row>

        {
          !isRole(ROLE.CLIENT, ROLE.CLEANER) &&
          <Row className="mt-10">
            <Col md={12} sm={24}>
              <label>Status:</label>
              <div>
                <Form.Control
                  container={() => container && container.current}
                  accepter={SelectPicker}
                  name="status"
                  cleanable={false}
                  searchable={false}
                  data={jobStatuses.length > 0 ? jobStatuses : CONFIG_STATUS}
                  style={{ width: '100%' }} />
              </div>
            </Col>

            {defaultFormValue.status === 'canceled' &&
              <Col md={12} sm={24}>
                <label className="required">Reason:</label>
                <div>
                  <Form.Control
                    name="canceledReason"
                    style={{ width: '100%' }} />
                </div>
              </Col>
            }
          </Row>
        }

        {!isRole(ROLE.CLIENT, ROLE.CLEANER) &&
          <Row className='mt-8'>
            <Col md={24}>
              <label>Customer Comments:</label>
              <div>
                <Input
                  as={TextareaAutosize}
                  rows={2}
                  style={{ width: '100%' }}
                  value={defaultFormValue.customerComments}
                  onChange={(val: string) => {
                    setDefaultFormValue({ ...defaultFormValue, customerComments: val })
                  }} />
              </div>
            </Col>
          </Row>
        }

        {
          (!isRole(ROLE.CLIENT, ROLE.CLEANER) && workOrderBookAs.indexOf('multiple_work_order') > -1) &&
          <Fragment>
            <Row className="mt-10">
              <Col md={24}>
                <label>Contract Value:</label>
                <Form.Control style={{ width: '100%' }} accepter={InputMoney} name="contractValue" />
                <Form.HelpText>Contract values are presumed monthly for duration of invoicing.
                  They will be automatically appended to all. If using contract value it is not required
                  to provide cost per visit.</Form.HelpText>
              </Col>
            </Row>
            <Row className="mt-10">
              <Col md={24}>
                <label>Contract Notes:</label>
                <Form.Control
                  accepter={TextareaAutosize}
                  rows={2}
                  style={{ width: '100%' }}
                  name="contractNotes" />
                <Form.HelpText>Contract notes will appear on the invoice export for office billing reference.</Form.HelpText>
              </Col>
            </Row>
          </Fragment>
        }

        <Row className="mt-10">
          <Col md={24}>
            <ul className='list-inline mt-10' style={{ marginBottom: 0 }}>
              <li>
                <Button size="xs" appearance="link" onClick={() => onJobAdd()}><MdAdd /> Add</Button>
              </li>
              <li>
                <Button size="xs" appearance="link" onClick={() => onJobDuplicate(formIndex)}><MdCopyAll /> Duplicate</Button>
              </li>
              <li>
                <Button size="xs" appearance="link" className='text-danger' onClick={() => onJobRemove(formIndex)}><MdDelete /> Remove</Button>
              </li>
            </ul>
          </Col>
        </Row>
      </Grid >
    </Form >
  );
}

export {
  WorkOrderFields
}