import React, { useState, useEffect, useRef, useContext } from 'react';
import { CustomerLookup, ApplicationContext, DrawerFormWrapper } from 'shared';
import { useApolloClient, gql, useQuery } from '@apollo/client';
import queryString from 'query-string';
import startCase from 'lodash/startCase';
import omit from 'lodash/omit';
import { setHeaderTitle, getEnv, STATUS, ILoadingState, FORMAT, ROLE, COLOR } from 'lib/env';
import { v4 as uuidv4 } from 'uuid';

import {
  Grid,
  Row,
  Col,
  Button,
  toaster,
  ButtonToolbar,
  Input,
  Form,
  Panel,
  Loader,
  Message,
  Whisper,
  Tooltip,
  DOMHelper,
  IconButton,
} from 'rsuite';

import LegacyCheckSquareOIcon from "@rsuite/icons/legacy/CheckSquareO";
import LegacySquareOIcon from "@rsuite/icons/legacy/SquareO";
import LegacyTrashIcon from "@rsuite/icons/legacy/Trash";
import DocumentServiceLineItem, { IDocumentService } from './components/DocumentServiceLineItem';
import { DocumentFields as PrairieDocumentFields } from './components/DocumentFields';
import { DocumentFieldsForUpload as KaladiKitchensDocumentFieldsForUpload } from './components/kaladikitchens/DocumentFieldsForUpload';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import ClientRepresentativeSignature from 'components/document/ClientRepresentativeSignature';
import { orderBy, pick } from 'lodash';
import { queryDocumentForm } from 'gql/documents';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import AttachmentForm from 'components/attachment/AttachmentForm';
import { useViewport } from 'shared/ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { format, getYear, parseISO } from 'date-fns';
import { setYear } from 'date-fns/esm';
import { getDefaultDate, toLocalTimeZone } from 'lib/date';
import { GET_TEMPLATE, GET_TEMPLATES_WITH_VERSIONS } from 'gql/templates';
import DocumentWorkOrders from './components/DocumentWorkOrders';
import { IS_BUGABOO, IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES } from 'lib/tenant';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import INTL from 'tenant/intl';
import { GET_TENANTS } from 'gql/tenant';

const defaultFormValue = {
  title: '',
  entryType: 'contract',
  documentNumber: '',
  templateId: 0,
  templateVersionId: 0,
  startEndDate: [new Date(), new Date()],
  expiryDate: null,
  documentDate: null,
  userId: [],
  responseTime: 0,
  additionalNotes: '',
  customerComments: '',
  workType: [],
  status: '',
  guid: uuidv4(),
  billingCustomerId: 0,
  workCustomerId: 0,
  attachments: [],
  workOrdersLinked: [],
};

const defaultService: IDocumentService = {
  id: '',
  guid: uuidv4(),
  service: '',
  workDescription: '',
  previousWorkDescription: '',
  workCost: '',
  previousWorkCost: '',
  people: '',
  manHours: '',
  isVisible: true,
  previousIsVisible: true,
  sortOrder: 0,
  status: undefined,
  templateServiceId: undefined,
  workType: '',
  costBreakdown: []
};

interface IDocumentForm {
  focusField?: string,
  action?: string,
  show?: boolean,
  customerId?: number,
  guid?: string,
  drawer?: boolean,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const SortableItem = SortableElement((props: any) => <li>
  <Grid fluid>
    <Row>
      <DocumentServiceLineItem {...props} />
    </Row>
  </Grid>
</li>);

const SortableList = SortableContainer((children: any) => {
  return <ul>{children.children}</ul>;
});

const DocumentForm = ({ guid, action, customerId, drawer, show, focusField, onHide, onUpdate }: IDocumentForm) => {
  let form: any;
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const client = useApolloClient();
  const match: any = useRouteMatch();
  const history = useHistory();
  const { state } = useViewport();
  const { can, canNot, isRole } = usePrairieAuth();
  const { showError, showConfirmation } = useContext(ApplicationContext);
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState<ILoadingState | null>({ state: STATUS.LOADED });
  const [formValue, setFormValue] = useState<any>({});
  const [formError, setFormError] = useState<any>({});
  const [services, setServices] = useState<Array<any>>([]);
  const [documentStatus, setDocumentStatus] = useState<Array<any>>([]);
  const [showCustomerRequiredTooltip, setShowCustomerRequiredTooltip] = useState(false);
  const [estimatedCost, setEstimatedCost] = useState("");
  const [oldCustomer, setOldCustomer] = useState<any>(undefined);
  const [customer, setCustomer] = useState<any>();
  const [documentSigning, setDocumentSigning] = useState(false);
  const [servicesUpdatedAt, setServicesUpdatedAt] = useState(new Date().toISOString());
  const [readOnly, setReadOnly] = useState(false);
  const [templateId, setTemplateId] = useState<number | undefined>(undefined);
  const [templateVersionId, setTemplateVersionId] = useState<number | undefined>(undefined);
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const params = queryString.parse(useLocation().search);
  const templates = useQuery(GET_TEMPLATES_WITH_VERSIONS, { variables: { offset: 0, limit: 999, order: [{ name: 'ASC' }] } });
  const [includePreviousCost, setIncludePreviousCost] = useState(false);
  const [includeCostBreakdown, setIncludeCostBreakdown] = useState(false);
  const [disableFormatting, setDisableFormatting] = useState(false);
  const tenants = useQuery(GET_TENANTS, { variables: { offset: 0, limit: 999, where: { customerId: { is: customerId } }, order: [{ name: 'ASC' }] } });

  guid = match?.params?.guid || params?.edit || guid;
  action = match?.params?.action || (params?.edit && 'edit') || action || 'add';

  useEffect(() => {
    if (action && guid && ['edit', 'repeat', 'clone'].includes(action)) {
      setHeaderTitle('Edit Document');

      (async function getDocument() {
        setStatus({ state: STATUS.LOADING });

        const data = await client.query({ query: queryDocumentForm, variables: { guid } });

        if (!data.data || !data.data.document) {
          setStatus({ state: STATUS.ERROR, message: 'Error retrieving document. Request has been logged for review.' });
          throw `${window.location.href} failed to retrieve data`;
        }

        const doc: any = data.data.document;

        const newForm: any = {
          ...doc,
          status: 'edit',
          workCustomerId: doc.customerId,
          billingCustomerId: doc.billingCustomerId > 0 ? doc.billingCustomerId : doc.customerId,
          workType: doc.workType === null ? [] : doc.workType.split(';'),
          startEndDate: [parseISO(doc.startDate), (doc.endDate ? parseISO(doc.endDate) : null)],
          templateId: +doc.templateId,
          documentDate: doc.documentDate ? parseISO(doc.documentDate) : null,
          expiryDate: typeof (doc.expiryDate) === 'string' ? parseISO(doc.expiryDate) : doc.expiryDate
        };

        if (doc.customFields) {
          Object.keys(doc.customFields).forEach((field: any) => {
            newForm['customField_' + field] = doc.customFields[field];
          })
        }

        if ((doc.workType === null ? [] : doc.workType.split(';')).includes('arborcare__242153b6__1644606571')) {
          setDisableFormatting(true);
        }

        const newServices = doc.services.map((s: any, index: number) => ({
          id: action === 'repeat' || action === 'clone' ? '' : s.id,
          guid: action === 'repeat' || action === 'clone' ? uuidv4() : s.guid,
          service: s.service,
          workDescription: s.workDescription,
          workCost: s.workCost,
          previousWorkCost: action === 'repeat' || action === 'clone' ? s.workCost : s.previousWorkCost,
          people: s.people,
          manHours: s.manHours,
          isVisible: s.isVisible ? true : false,
          previousIsVisible: s.isVisible ? true : false,
          sortOrder: index,
          status: action === 'repeat' || action === 'clone' ? undefined : s.status,
          templateServiceId: s.templateServiceId,
          workType: doc.workType,
          costBreakdown: s.costBreakdown
        }));

        if (action === 'repeat' || action === 'clone' || action === 'edit') {
          const hasCostBreakdown = doc.services.map((s: any) => (s.costBreakdown || []).length).reduce((p: any, n: any) => p += n, 0);
          setIncludeCostBreakdown(hasCostBreakdown > 0);
        }

        if (action === 'repeat' || action === 'clone') {
          const currTemplate: any = await client.query({ query: GET_TEMPLATE, variables: { id: newForm.templateId } });
          newForm.guid = uuidv4();
          newForm.status = 'repeat';
          newForm.approvalStatus = null;
          newForm.clientSignature = undefined;
          newForm.clientDateSigned = undefined;
          newForm.representativeDateSigned = '';
          newForm.representativeSignature = '';
          newForm.newStatus = state.fields.find((f: any) => f?.props?.useAsDefaultRenewal)?.key || '';
          newForm.templateVersionId = currTemplate.data.template.versions.length ? currTemplate.data.template.versions[0].id : newForm.templateVersionId;

          setDocumentStatus([]);
        } else {
          setDocumentStatus(doc.statuses || []);
        }

        if (action === 'repeat') {
          const startYear = getYear(newForm.startEndDate[0]);
          const endYear = getYear(newForm.startEndDate[1]);
          const newStartYear = new Date().getFullYear() + (startYear === new Date().getFullYear() ? 1 : 0);
          const newEndYear = startYear === endYear ? newStartYear : newStartYear + 1;

          newForm.startEndDate[0] = setYear(newForm.startEndDate[0], newStartYear);
          newForm.startEndDate[1] = setYear(newForm.startEndDate[1], newEndYear);
        }

        newForm.userId = Array.isArray(doc.userId) ? doc.userId : [];

        setEstimatedCost(doc.estimatedCost ? doc.estimatedCost.toString() : "0");

        if (newServices.length === 0) {
          setServices([{ ...defaultService, guid: uuidv4() }]);
        } else {
          setServices(newServices);
        }

        setTemplateId(newForm.templateId);
        setTemplateVersionId(newForm.templateVersionId);
        setFormValue(newForm);
        setStatus({ state: STATUS.LOADED });
        setReadOnly(isRole('client') || (!!newForm.clientDateSigned && !!newForm.representativeDateSigned && newForm.clientDateSigned.length > 0 && newForm.representativeDateSigned.length > 0));
        setIncludePreviousCost(newForm.services.filter((s: any) => s.previousWorkCost).length > 0);

        // bugaboo office work
        // isRole(ROLE.CREW, ROLE.FRANCHISE) && 
        // console.log(doc?.statuses?.[0]?.status);
        if (IS_BUGABOO && doc?.statuses?.[0]?.status === 'up_for_renewal_0qn9cjmu2p' && doc?.workType !== 'christmas_lights' && isRole(ROLE.CREW, ROLE.FRANCHISE)) {
          setReadOnly(true);
        }

        if (!drawer) {
          DOMHelper.scrollTop(window as any, 0);
        }
      })();

      setTimeout(() => {
        const el = (window as any).document.querySelector(`[name=${focusField}]`);

        if (el) {
          el.focus();
        }
      }, 500);
    } else {
      setHeaderTitle('New ' + INTL.DOCUMENT);

      (async function getData() {
        const companyResult = await client.query({
          query: gql`{
            company(id: ${+state.profile.companyId} ) { newDocumentStatus } }`
        });

        const parsed = queryString.parse(window.location.search);

        setFormValue({
          ...defaultFormValue,
          guid: uuidv4(),
          billingCustomerId: customerId || 0,
          workCustomerId: customerId || 0,
          newStatus: companyResult?.data?.company?.newDocumentStatus
        });
        setServices([{ ...defaultService, guid: uuidv4() }]);
      })();
    }
  }, [guid, modifiedAt]);

  const handleCustomerIdChange = (workCustomerId: number, billingCustomerId: number, userId: number | undefined) => {
    if (form?.workCustomerId !== workCustomerId || form?.billingCustomerId !== billingCustomerId) {
      setShowCustomerRequiredTooltip(false);
      setFormValue({ ...formValue, workCustomerId, billingCustomerId });
    }
  }

  const handleAddService = (guid: string) => {
    const nextState = services.map((s) => s);
    const index = services.findIndex((s) => s.guid === guid);
    nextState.splice(index + 1, 0, { ...defaultService, guid: uuidv4(), workType: formValue.workType });

    setServices(nextState.map((s: any, index: number) => {
      s.sortOrder = index;
      return s;
    }));
  }

  const handleServicesUpdateReplace = (val: number | undefined, action: string) => {
    if (!val) {
      toaster.push(
        <Message type="error" showIcon closable>{`Select template to ${action} from`}</Message>
      );
      return;
    }

    handleServicesAutoPopulate(val, action);
  }

  const handleServicesAutoPopulate = (val: number, action = 'replace') => {
    const temp = templates.data.templates.edges.node.find((t: any) => t.id === val);

    if (temp && temp.services.groups && temp.services.groups.length > 0) {
      if (action === 'update') {
        let nextStates = [] as Array<IDocumentService>;
        let sortOrder = 0;

        orderBy(temp.services.groups, 'sort_order')
          .forEach((g: any) => {
            nextStates.push({
              id: '',
              guid: uuidv4(),
              service: g.op_code,
              workDescription: g.description,
              workCost: '',
              previousWorkCost: '',
              people: '',
              manHours: '',
              isVisible: true,
              previousIsVisible: true,
              sortOrder,
              status: g.status,
              templateServiceId: g.guid,
              workType: formValue.workType,
              costBreakdown: g.costBreakdown || []
            });
            sortOrder++;

            orderBy(temp.services.services.filter((s: any) => s.group_guid === g.guid), 'sort_order')
              .forEach((s: any) => {
                let knownService = services.find((k: any) => k.templateServiceId === s.guid);

                if (!knownService) {
                  knownService = services.find((k: any) => k.workDescription === s.description);
                }

                if (!knownService) {
                  knownService = services.find((k: any) => k.service === s.op_code);
                }

                if (knownService) {
                  nextStates.push({
                    id: '',
                    guid: uuidv4(),
                    service: s.op_code,
                    workDescription: s.description || '',
                    workCost: knownService.workCost || '',
                    previousWorkCost: knownService.previousCost || '',
                    previousWorkDescription: knownService.workDescription,
                    people: '',
                    manHours: '',
                    isVisible: true,
                    previousIsVisible: true,
                    sortOrder,
                    status: s.status,
                    templateServiceId: s.guid,
                    workType: formValue.workType,
                    costBreakdown: s.costBreakdown || []
                  });
                } else {
                  nextStates.push({
                    id: '',
                    guid: uuidv4(),
                    service: s.op_code,
                    workDescription: s.description || '',
                    workCost: s.cost || '',
                    previousWorkCost: s.previousCost || '',
                    people: '',
                    manHours: '',
                    isVisible: true,
                    previousIsVisible: true,
                    sortOrder,
                    status: s.status,
                    templateServiceId: s.guid,
                    workType: formValue.workType,
                    costBreakdown: s.costBreakdown || []
                  });
                }

                sortOrder++;
              });

            sortOrder++;
          });

        // // remove non existing
        // const knownServiceIds = temp.services.groups.map((g: any) => g.guid).concat(temp.services.services.map((s: any) => s.guid));
        
        // let nextStates: Array<IDocumentService> = [].concat(services.map((s: any) => {
        //   if (s?.templateServiceId && !knownServiceIds.includes(s.templateServiceId)) {
        //     s.isVisible = false;
        //     s.previousWorkDescription = s.workDescription;
        //     return s;
        //   }

        //   return s;
        // }) as any);

        // orderBy(temp.services.groups, 'sort_order')
        //   .forEach((g: any) => {
        //     const group: any = nextStates.find((n: any) => n.templateServiceId === g.guid);

        //     if (group) {
        //       nextStates = nextStates.map((n: any) => {
        //         if (n.templateServiceId === g.guid) {
        //           n.previousWorkDescription = n.workDescription;
        //           n.workDescription = g.description;
        //           n.service = g.op_code;
        //           return n;
        //         }

        //         return n;
        //       });
        //     } else {
        //       // add new group
        //       nextStates.push({
        //         id: '',
        //         guid: uuidv4(),
        //         service: g.op_code,
        //         workDescription: g.description,
        //         workCost: '',
        //         previousWorkCost: '',
        //         people: '',
        //         manHours: '',
        //         isVisible: true,
        //         previousIsVisible: true,
        //         sortOrder: 0,
        //         status: g.status,
        //         templateServiceId: g.guid,
        //         workType: formValue.workType,
        //         costBreakdown: g.costBreakdown || []
        //       });

        //       orderBy(temp.services.services.filter((s: any) => s.group_guid === g.guid), 'sort_order')
        //         .forEach((s: any) => {
        //           nextStates.push({
        //             id: '',
        //             guid: uuidv4(),
        //             service: s.op_code,
        //             workDescription: s.description || '',
        //             workCost: s.cost || '',
        //             previousWorkCost: s.previousCost || '',
        //             people: '',
        //             manHours: '',
        //             isVisible: true,
        //             previousIsVisible: true,
        //             sortOrder: 0,
        //             status: s.status,
        //             templateServiceId: s.guid,
        //             workType: formValue.workType,
        //             costBreakdown: s.costBreakdown || []
        //           });
        //         });
        //     }

        //     let lastKnownGuid = '';


        //     orderBy(temp.services.services.filter((s: any) => s.group_guid === g.guid), 'sort_order')
        //       .forEach((s: any) => {
        //         // insert new ones
        //         const serviceExists = nextStates.find((n: any) => n?.templateServiceId === s.guid);

        //         if (!serviceExists) {
        //           const servicePosition = nextStates.findIndex((n: any) => n?.templateServiceId === lastKnownGuid);
        //           nextStates.splice(servicePosition + 1, 0, {
        //             id: '',
        //             guid: uuidv4(),
        //             service: s.op_code,
        //             workDescription: s.description || '',
        //             workCost: s.cost || '',
        //             previousWorkCost: s.previousCost || '',
        //             people: '',
        //             manHours: '',
        //             isVisible: true,
        //             previousIsVisible: true,
        //             sortOrder: 0,
        //             status: s.status,
        //             templateServiceId: s.guid,
        //             workType: formValue.workType,
        //             costBreakdown: s.costBreakdown || []
        //           });
        //         }

        //         lastKnownGuid = s.guid;

        //         nextStates = nextStates.map((n: any) => {
        //           if (n?.templateServiceId === s.guid) {
        //             n.previousWorkDescription = n.workDescription;
        //             n.workDescription = s.description;
        //             return n;
        //           }

        //           return n;
        //         });
        //       });
        //   });

        nextStates = nextStates.map((n: any, sortOrder: number) => ({ ...n, sortOrder }));
        setServices(nextStates);
        setServicesUpdatedAt(new Date().toISOString());
      } else if (action === 'replace') {
        const nextStates = [] as Array<IDocumentService>;
        let sortOrder = 0;

        orderBy(temp.services.groups, 'sort_order')
          .forEach((g: any) => {
            nextStates.push({
              id: '',
              guid: uuidv4(),
              service: g.op_code,
              workDescription: g.description,
              workCost: '',
              previousWorkCost: '',
              people: '',
              manHours: '',
              isVisible: true,
              previousIsVisible: true,
              sortOrder,
              status: g.status,
              templateServiceId: g.guid,
              workType: formValue.workType,
              costBreakdown: g.costBreakdown || []
            });
            sortOrder++;

            orderBy(temp.services.services.filter((s: any) => s.group_guid === g.guid), 'sort_order')
              .forEach((s: any) => {
                nextStates.push({
                  id: '',
                  guid: uuidv4(),
                  service: s.op_code,
                  workDescription: s.description || '',
                  workCost: s.cost || '',
                  previousWorkCost: s.previousCost || '',
                  people: '',
                  manHours: '',
                  isVisible: true,
                  previousIsVisible: true,
                  sortOrder,
                  status: s.status,
                  templateServiceId: s.guid,
                  workType: formValue.workType,
                  costBreakdown: s.costBreakdown || []
                });

                sortOrder++;
              });

            sortOrder++;
          });

        setServices(nextStates);
        setServicesUpdatedAt(new Date().toISOString());
      }
    } else {
      toaster.push(
        <Message type="error" showIcon closable>No services assigned to template</Message>
      );
    }
  }

  const handleRemoveService = (guid: string) => {
    if (services.length === 1) {
      toaster.push(
        <Message type="error" showIcon closable>Cannot remove last item in list</Message>
      );
      return;
    }
    const nextState = services.filter((s) => s.guid !== guid);
    setServices(nextState.map((s: any, index: number) => {
      s.sortOrder = index;
      return s;
    }));
  }

  const handleUpdateService = (guid: string, key: string, val: any) => {
    const index = services.findIndex((s) => s.guid === guid);
    services[index][key] = val;
    setServices(services);
  }

  const handleUpdateServiceAll = (guid: string, service: string, description: string, templateServiceId: string) => {
    const index = services.findIndex((s) => s.guid === guid);
    services[index].service = service;
    services[index].workDescription = description;
    services[index].templateServiceId = templateServiceId;
    setServices(services);
  }

  const handleUpdateVisibility = (guid: string, isVisible: boolean) => {
    const tempServices = [].concat(services as any);
    const index = tempServices.findIndex((s: any) => s.guid === guid);
    (tempServices[index] as any).isVisible = isVisible;
    (tempServices[index] as any).previousIsVisible = isVisible;
    setServices(tempServices);
    setServicesUpdatedAt(new Date().toISOString());
  }

  const handleSetRef = (ref: any) => {
    form = ref;
  }

  const handleSortEnd = ({ oldIndex, newIndex }: any) => {
    const newSort = arrayMove(services, oldIndex, newIndex)
      .map((s: any, index: number) => {
        s.sortOrder = index;
        return s;
      });
    setServices(newSort);
  };

  const handleSubmit = async (stay: boolean, fromSignature: boolean) => {
    try {
      const errorMessage = 'Form has errors which need to be corrected';
      const validation = form.check();

      if (!formValue.billingCustomerId || !formValue.workCustomerId) {
        setShowCustomerRequiredTooltip(true);
        toaster.push(<Message type="error" showIcon closable>{errorMessage}</Message>);
      } else if (!validation) {
        toaster.push(<Message type="error" showIcon closable>{errorMessage}</Message>);
      } else {
        setSaving(true);

        const upsertDocument = gql`
        mutation upsertDocument($input: UpsertDocumentInput!) {
          upsertDocument(input: $input) {
            success
            code
            message
            result
          }
        }`;

        const estimatedCost = +(document as any).getElementById('estimated-cost')?.value;
        const response: any = await client.mutate({
          mutation: upsertDocument, variables: {
            // the array is taken from the input list
            input: pick({
              ...formValue,
              billingCustomerId: +formValue.billingCustomerId > 0 ? +formValue.billingCustomerId : null,
              workCustomerId: +formValue.workCustomerId,
              services: services.map((s: any) => omit(s, ['id', 'previousIsVisible', 'previousWorkDescription', 'workType'])),
              estimatedCost: estimatedCost > 0 ? estimatedCost : null,
              templateId: templateId,
              templateVersionId: templateVersionId,
              repeatGuid: action === 'repeat' || action === 'clone' ? guid : undefined,
              startEndDate: [
                toLocalTimeZone(getDefaultDate(formValue.startEndDate[0]), state.timeZone),
                toLocalTimeZone(getDefaultDate(formValue.startEndDate[1]), state.timeZone)
              ],
              customFields: Object.keys(formValue).reduce((p: any, n: any) => {
                if (n.includes('customField_')) {
                  p[n.replace('customField_', '')] = formValue[n];
                }

                return p;
              }, {})
            },
              ['guid', 'title', 'entryType', 'newStatus', 'notes', 'workType', 'customerId', 'workCustomerId', 'billingCustomerId', 'startEndDate', 'expiryDate', 'documentDate', 'additionalNotes', 'customerComments', 'documentNumber', 'templateId', 'templateVersionId', 'estimatedCost', 'status', 'userId', 'approvalStatus', 'clientComments', 'services', 'attachments', 'workOrdersLinked', 'repeatGuid', 'customFields']
            )
          }
        });

        if (response.data.upsertDocument.success) {
          toaster.push(
            <Message type="success" showIcon closable>{response.data.upsertDocument.message}</Message>
          );
          setSaving(false);

          if (fromSignature) {
            return;
          }

          if (drawer) {
            if (oldCustomer && customer && oldCustomer.id !== customer.id) {
              history.push(`/app/${getEnv()}/workbook/explorer/${customer.id}/documents`);
            } else {
              onHide && onHide();
              onUpdate && onUpdate({});
            }
          } else if (stay) {
            history.push(`/app/${getEnv()}/document/edit/${response.data.upsertDocument.result.guid}`);
          } else if (!stay) {
            history.push(`/app/${getEnv()}/workbook/explorer/${response.data.upsertDocument.result.customerId}/documents`);
          }
        } else {
          toaster.push(
            <Message type="error" showIcon closable duration={3000}>{response.data.upsertDocument.message}</Message>
          );
        }
      }
    } catch (err) {
      showError(err);
      setSaving(false);
    }
  };

  const handleRemoveApproval = async (type: String) => {
    try {
      const response: any = await client.mutate({
        mutation: gql`
          mutation clearDocumentApproval(
            $guid: ID!,
            $type: String!
          ) {
            clearDocumentApproval(
              guid: $guid,
              type: $type
            ) {
              success
              code
              message
            }
          }
        `,
        variables: { guid: formValue.guid, type }
      });

      if (response.data.clearDocumentApproval.success) {
        if (type === 'client') {
          setFormValue({ ...formValue, approvalStatus: null, clientDateSigned: '', clientSignature: '' });
        } else if (type === 'representative') {
          setFormValue({ ...formValue, approvalStatus: null, representativeDateSigned: '', representativeSignature: '' });
        }
      }
      toaster.push(
        <Message type="success" showIcon closable>{response.data.clearDocumentApproval.message}</Message>
      );
    } catch (err) {
      showError(err);
    }
  }

  const handleCustomerFound = (customer: any) => {
    if (!oldCustomer) {
      setOldCustomer(customer);
    }

    // preset work type for kitchens
    if (customer?.user?.accessGroup && IS_KALADI_KITCHENS) {
      setFormValue({ ...formValue, workType: customer?.user?.accessGroup || [] });
    }

    setCustomer(customer);
  }

  const handleSetAttachments = (attachments: any) => {
    const nextFormValue = { ...formValue };

    nextFormValue.attachments = [].concat(attachments);
    nextFormValue.startEndDate = [
      typeof (nextFormValue.startEndDate[0]) === 'string' ? parseISO(nextFormValue.startEndDate[0]) : nextFormValue.startEndDate[0],
      typeof (nextFormValue.startEndDate[1]) === 'string' ? parseISO(nextFormValue.startEndDate[1]) : nextFormValue.startEndDate[1]
    ];
    setFormValue(nextFormValue);
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      action={action || 'add'}
      loading={saving}
      show={show}
      title={startCase(action) + ' ' + INTL.DOCUMENT}
      guid={guid}
      form={`/app/${getEnv()}/document/${action}/${guid}`}
      onHide={onHide}
      onSave={(stay: boolean) => handleSubmit(stay, false)}
    />;

  return React.cloneElement(Wrapper, {
    children: <div>
      {((status && status.state === STATUS.LOADING) || templates.loading) &&
        <Loader content="Loading..." />
      }

      {(status && status.state === STATUS.LOADED && !templates.loading) &&
        <div ref={drawer ? containerRef : undefined}>
          {(!isRole(ROLE.CLIENT) && !IS_KALADI_PROPERTIES) &&
            <CustomerLookup
              showBreadcrumb={!drawer && formValue.status}
              breadcrumbTitle={`${startCase(formValue.status)} ${INTL.DOCUMENT}`}
              showAutoCompleted={['add', 'repeat', 'clone'].includes(action || '')}
              billingCustomerId={formValue.billingCustomerId}
              workingCustomerId={formValue.workCustomerId}
              handleCustomerIdChange={handleCustomerIdChange}
              handleCustomerFound={handleCustomerFound}
              showRequiredTooltip={showCustomerRequiredTooltip}
              topSection={'documents'}
            />
          }

          <Panel className="content">
            {(IS_KALADI_KITCHENS && formValue?.templateId === 95)
              ? <KaladiKitchensDocumentFieldsForUpload
                readOnly={readOnly}
                templates={templates.data.templates.edges.node}
                container={containerRef}
                statusFields={filterFieldsByParentKey(state.fields, 'documents')}
                drawer={drawer}
                action={action === 'edit' ? 'edit' : ''}
                key={formValue.guid}
                formValue={formValue}
                formError={formError}
                status={documentStatus}
                handleSetRef={handleSetRef}
                handleServicesAutoPopulate={handleServicesAutoPopulate}
                handleTemplateChange={setTemplateId}
                handleVersionChange={setTemplateVersionId}
                templateId={templateId}
                templateVersionId={templateVersionId}
                handleChange={setFormValue}
                handleCheck={setFormError}
              />
              : <PrairieDocumentFields
                readOnly={readOnly}
                templates={templates.data.templates.edges.node}
                container={containerRef}
                statusFields={filterFieldsByParentKey(state.fields, 'documents')}
                drawer={drawer}
                action={action === 'edit' ? 'edit' : ''}
                key={formValue.guid}
                formValue={formValue}
                formError={formError}
                status={documentStatus}
                handleSetRef={handleSetRef}
                handleServicesAutoPopulate={handleServicesAutoPopulate}
                handleTemplateChange={setTemplateId}
                handleVersionChange={setTemplateVersionId}
                templateId={templateId}
                templateVersionId={templateVersionId}
                handleChange={setFormValue}
                handleCheck={setFormError}
                tenants={tenants?.data?.tenants?.edges?.node || []}
              />
            }

            {((IS_KALADI_KITCHENS && formValue?.templateId !== 95) || (!IS_KALADI_KITCHENS)) &&
              <fieldset className="sortable-services">
                {readOnly && <Message className="mb-20" type="info">Services are read only on {(INTL.DOCUMENTS || 'documents').toLowerCase()} signed by both client and representative</Message>}

                <div>
                  <legend>Services</legend>
                  {can('document:update') &&
                    <ButtonToolbar className='mb-8'>
                      <Whisper placement='bottomStart' speaker={<Tooltip>{(action === 'edit' || action === 'clone') && includeCostBreakdown ? 'Cannot be disabled due to attached cost break downs' : 'Include detailed cost breakdown of each line item'}</Tooltip>}>
                        <IconButton appearance='default'
                          size='xs'
                          // disabled={(action === 'edit' || action === 'clone') && includeCostBreakdown}
                          onClick={() => setIncludeCostBreakdown(!includeCostBreakdown)}
                          icon={includeCostBreakdown ? <LegacyCheckSquareOIcon /> : <LegacySquareOIcon />}>Include Cost Breakdown</IconButton>
                      </Whisper>
                      <IconButton appearance='default'
                        size="xs"
                        onClick={() => {
                          if ((!includePreviousCost)) {
                            setServices(services.map((s: any) => ({
                              ...s,
                              previousWorkCost: IS_BUGABOO ? (s.previousWorkCost || s.workCost || '').toString().toUpperCase() : (s.previousWorkCost || s.workCost || '').toString()
                            })));
                          }
                          setIncludePreviousCost(!includePreviousCost);
                          setServicesUpdatedAt(new Date().toISOString());
                        }}
                        icon={includePreviousCost ? <LegacyCheckSquareOIcon /> : <LegacySquareOIcon />}>Include Previous Cost</IconButton>
                      <IconButton appearance='default'
                        size="xs"
                        onClick={() => setDisableFormatting(!disableFormatting)}
                        icon={disableFormatting ? <LegacyCheckSquareOIcon /> : <LegacySquareOIcon />}>Disable Formatting</IconButton>
                      <Whisper placement='bottom' speaker={<Tooltip>Update descriptions from template</Tooltip>}>
                        <Button appearance='default' size="xs" disabled={readOnly}
                          onClick={() => showConfirmation(
                            <p>Are you sure you want to update descriptions from template? This action will update descriptions and insert missing line items.</p>,
                            'Update Descriptions From Template',
                            () => handleServicesUpdateReplace(templateId, 'update')
                          )}>Update</Button>
                      </Whisper>
                      <Whisper placement='bottom' speaker={<Tooltip>Replace all lines items template</Tooltip>}>
                        <Button appearance='default' size="xs" disabled={readOnly}
                          onClick={() => showConfirmation(
                            <p>Are you sure you want to replace line items from template? This action will replace all current line items.</p>,
                            'Replace From Template',
                            () => handleServicesUpdateReplace(templateId, 'replace')
                          )}>Replace</Button>
                      </Whisper>
                    </ButtonToolbar>
                  }
                </div>

                <Grid fluid>
                  <Row>
                    <Col md={5}>Service</Col>
                    <Col md={includePreviousCost ? 9 : 12}>Description</Col>
                    {includePreviousCost &&
                      <Col md={3}>Previous Cost</Col>
                    }
                    <Col md={3}>
                      Cost
                    </Col>
                    <Col md={3}>&nbsp;</Col>
                  </Row>
                </Grid>
                <SortableList key={servicesUpdatedAt} onSortEnd={handleSortEnd} helperClass="sortableHelper" useDragHandle>
                  {services.map((s) =>
                    <SortableItem
                      index={s.sortOrder}
                      {...s}
                      container={containerRef}
                      key={s.guid}
                      readOnly={readOnly}
                      handleAddService={handleAddService}
                      handleRemoveService={handleRemoveService}
                      handleUpdateService={handleUpdateService}
                      handleUpdateServiceAll={handleUpdateServiceAll}
                      handleUpdateVisibility={handleUpdateVisibility}
                      template={templates.data.templates.edges.node.find((t: any) => t.id === templateId)}
                      includePreviousCost={includePreviousCost}
                      disableFormatting={disableFormatting}
                      documentStatus={documentStatus}
                      includeCostBreakdown={includeCostBreakdown}
                    />
                  )}
                </SortableList>

                {(!IS_KALADI_KITCHENS && !IS_KALADI_PROPERTIES) &&
                  <div className="mt-10">
                    <label>Estimated Cost</label>
                    <Input
                      disabled={canNot('document:update')}
                      key={estimatedCost}
                      id="estimated-cost"
                      defaultValue={estimatedCost}
                    />
                    <Form.HelpText>This value is auto calculated from price field, but may not reflect actual cost; update it as necessary</Form.HelpText>
                  </div>
                }
              </fieldset>
            }

            <fieldset>
              <legend>Attachments</legend>
              <AttachmentForm
                disabled={canNot('document:update')}
                referenceGuid={formValue.guid}
                attachments={formValue.attachments || []}
                onChange={handleSetAttachments}
                showVisibility={!IS_KALADI_KITCHENS}
                showType={!IS_KALADI_KITCHENS && filterFieldsByParentKey(state.fields, 'attachment_types').length > 0}
              />
            </fieldset>

            {(action !== 'repeat' && action !== 'clone' && can('document:update') && !IS_KALADI_KITCHENS) &&
              <fieldset>
                <legend>Work Orders</legend>
                <DocumentWorkOrders
                  workOrdersLinked={formValue.workOrdersLinked}
                  customerId={formValue.workCustomerId}
                  document={formValue}
                  onChange={(workOrdersLinked: string[]) => {
                    setFormValue({ ...formValue, workOrdersLinked });
                  }}
                />
              </fieldset>
            }

            {(can('document:update') && ((IS_KALADI_KITCHENS && formValue?.templateId !== 95) || !IS_KALADI_KITCHENS)) &&
              <fieldset>
                <legend>Approval</legend>
                <Grid fluid className="mb-6">
                  <Row>
                    <Col xs={11} md={6}>
                      <h6>Client Signature</h6>
                      {formValue.clientDateSigned?.length > 0
                        ? <div>
                          <div style={{ position: 'relative' }}>
                            <div style={{ color: '#fff', position: 'absolute', right: 0, top: 0 }}>
                              <Whisper placement="bottom" speaker={<Tooltip>Clear client signature</Tooltip>}>
                                <Button size="xs" className={'pt-0 pl-0 text-danger'} appearance="link" onClick={() => {
                                  showConfirmation(
                                    <p>Are you sure you want to clear approval and signature from this document?</p>,
                                    'Remove Signature & Approval',
                                    () => handleRemoveApproval('client')
                                  );
                                }} loading={saving}><LegacyTrashIcon /></Button>
                              </Whisper>
                            </div>
                            <div className="p-2" style={{ color: '#fff', width: '100%', position: 'absolute', left: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.5)' }}>
                              {formValue.clientFullName} on {format(parseISO(formValue.clientDateSigned), FORMAT.DATE_TIME)}
                            </div>
                            <img style={{ height: '60px', width: 'auto' }} src={formValue.clientSignature} />
                          </div>
                          {formValue.clientJobTitle && <div>Title: {formValue.clientJobTitle}</div>}
                          {formValue.clientComments && <div>Comments: {formValue.clientComments}</div>}
                        </div>
                        : <div>Client approval pending</div>
                      }
                    </Col>
                    <Col xs={2} md={1}></Col>
                    <Col xs={11} md={6}>
                      <h6>Representative Signature</h6>
                      {formValue.representativeDateSigned?.length > 0
                        ? <div>
                          <div style={{ position: 'relative' }}>
                            <div style={{ color: '#fff', position: 'absolute', right: 0, top: 0 }}>
                              <Whisper placement="bottom" speaker={<Tooltip>Clear representative signature</Tooltip>}>
                                <Button size="xs" className={'pt-0 pl-0 text-danger'} appearance="link" onClick={() => {
                                  showConfirmation(
                                    <p>Are you sure you want to clear approval and signature from this document?</p>,
                                    'Remove signature & approval',
                                    () => handleRemoveApproval('representative')
                                  );
                                }} loading={saving}><LegacyTrashIcon /></Button>
                              </Whisper>
                            </div>
                            <div className="p-2" style={{ color: '#fff', width: '100%', position: 'absolute', left: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.5)' }}>
                              {formValue.representativeFullName} on {format(parseISO(formValue.representativeDateSigned), FORMAT.DATE_TIME)}
                            </div>
                            <img style={{ height: '60px', width: 'auto' }} src={formValue.representativeSignature} />
                          </div>
                          {formValue.clientJobTitle && <div>Title: {formValue.representativeJobTitle}</div>}                          
                          {formValue.representativeComments && <div>Comments: {formValue.representativeComments}</div>}
                        </div>
                        : <div>
                          Represenative approval pending<br />
                          <Button size="xs" className={'p-0'} appearance="link" onClick={() => setDocumentSigning(true)}>Sign Document</Button>
                        </div>
                      }
                    </Col>
                  </Row>
                </Grid>

                {readOnly &&
                  <Button
                    style={{ backgroundColor: COLOR.DANGER }}
                    size="sm"
                    appearance="primary"
                    onClick={() => {
                      showConfirmation('Are you sure you want to remove client and representative signatures from this ' + (INTL.DOCUMENT || 'document').toLowerCase() + '?', 'Remove Signature', async () => {
                        const response = await client.mutate({
                          mutation: gql`mutation clearDocumentSignatures($guid: ID!) { clearDocumentSignatures(guid: $guid) { success, code, message }}`,
                          variables: { guid: formValue.guid }
                        });

                        if (response.data.clearDocumentSignatures.success) {
                          setFormValue({
                            ...formValue,
                            representativeSignature: '',
                            representativeFullName: '',
                            representativeDateSigned: '',
                            clientSignature: '',
                            clientFullName: '',
                            clientDateSigned: ''
                          })
                          toaster.push(
                            <Message type="success" showIcon closable>{response.data.clearDocumentSignatures.message}</Message>
                          );
                        } else {
                          showError(response.data.clearDocumentSignatures.message);
                        }
                      });
                    }}>Clear Signatures</Button>
                }
              </fieldset>
            }

            {!drawer &&
              <ButtonToolbar>
                <Button
                  appearance="primary"
                  onClick={() => handleSubmit(false, false)}
                  loading={saving}>Save</Button>
                <Button
                  appearance="ghost"
                  onClick={() => handleSubmit(true, false)}
                  loading={saving}>Save &amp; Stay</Button>
              </ButtonToolbar>
            }
          </Panel>
        </div>
      }

      <ClientRepresentativeSignature
        handleSave={() => handleSubmit(true, true)}
        customer={customer}
        document={formValue}
        show={documentSigning}
        saving={saving}
        onHide={() => {
          setDocumentSigning(false);
        }}
        onApproveDocument={(doc: any) => {
          setFormValue({
            ...formValue,
            representativeSignature: doc.signature,
            representativeFullName: doc.fullName,
            representativeDateSigned: new Date().toISOString()
          })
          setDocumentSigning(false);
        }}
      />
    </div>
  });
}

export {
  DocumentForm
}
