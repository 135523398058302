import { Fragment, useContext } from 'react';
import startCase from 'lodash/startCase';
import { Whisper, Tooltip, Badge, IconButton, Dropdown, Button } from 'rsuite';
import LegacyArrowDownLineIcon from "@rsuite/icons/legacy/ArrowDownLine";
import { ApplicationContext, PopoverDropdownMenu } from 'shared';
import { COLOR, DRAWER, FORMAT, getEnv, ROLE } from 'lib/env';
import { parseISO } from 'lib/date';
import { format } from 'date-fns';
import { moneyFormatter } from 'lib/tenant';
import { useViewport } from 'shared/ViewportProvider';
import { isBrowser, isMobileOnly } from 'react-device-detect';
import { fieldTitle, filterFieldsByParentKey } from 'lib/helpers/field';
import { Link } from 'react-router-dom';
import { useApi } from 'lib';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { MdAccessTimeFilled, MdAttachFile, MdCameraAlt, MdFlag, MdMail, MdMarkEmailRead } from 'react-icons/md';
import store from 'lib/storage';

interface ICustomerName {
  job: any,
  jobsRecentlyCompleted?: any,
  handleSummaryShow: (customerId: number, workOrderParentGuid: string) => void,
  handleShowDrawer: (jobGuid: string, type: string) => void,
  handleWorkOrderSelect: (guid: string | undefined, startEndDate: [Date, Date] | undefined) => void,
  handleJobRefresh: (guid: string) => void,
  handleJobUpdate?: (guid: string, data: any, selection: any) => void
}

const StatusColor = (status: string) => {
  if (status === 'completed') {
    return COLOR.SUCCESS;
  } else if (status === 'canceled') {
    return COLOR.WARNING;
  }

  return COLOR.DANGER;
};

const StatusBadge = ({ status }: any) => {
  if (status === 'completed') {
    return <Badge style={{ backgroundColor: StatusColor(status) }} content="Completed" />;
  } else if (status === 'canceled') {
    return <Badge style={{ backgroundColor: StatusColor(status) }} content="Canceled" />;
  }

  return <Badge style={{ backgroundColor: StatusColor(status) }} content="Not Completed" />;
};

const RecentlyCompletedServices = (currentServiceTitle: string, jobsRecentlyCompleted: any, serviceKeys: any, fields: any) => {
  const servicesCompleted: string[] = [];
  const recentlyCompleted = (jobsRecentlyCompleted || []).map((jrc: any) => {
    const previousServiceTitle = fieldTitle((jrc.services || []).filter((s: any) => serviceKeys.includes(s)), fields).join(', ');

    if (previousServiceTitle !== currentServiceTitle && !servicesCompleted.includes(previousServiceTitle)) {
      servicesCompleted.push(previousServiceTitle);
      let short = previousServiceTitle.substring(0, 1);
      if (previousServiceTitle.toLowerCase().includes('sand')) {
        short = 'SD';
      } else if (previousServiceTitle.toLowerCase().includes('salt')) {
        short = 'ST';
      }

      return <span key={`${jrc.guid}-${short}`}><Whisper placement="top" trigger="hover" speaker={<Tooltip>{previousServiceTitle + ' on ' + format(parseISO(jrc.completedAt), FORMAT.SHORT_DAY_TIME24)}</Tooltip>}>
        <Badge content={short} color="blue" />
      </Whisper><span> </span></span>;
    }

    return undefined;
  })
    .filter((title: string) => title);

  if (recentlyCompleted.length > 0) {
    return recentlyCompleted;
  }

  return '';
}

const CustomerName = ({ job, jobsRecentlyCompleted, handleShowDrawer, handleJobRefresh, handleJobUpdate }: ICustomerName) => {
  const { showDrawer } = useContext(ApplicationContext);
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const api = useApi();
  const jobTypes = filterFieldsByParentKey(state.fields, 'work_types').filter((w: any) => w.visibleOnJob);
  const jobsColumns = store.session.get('jobsColumns', []);
  const trackingFlags = state.fields.filter((s: any) => s.parentKey === 'tracking_flags');
  const serviceKeys = filterFieldsByParentKey(state.fields, 'work_types').filter((w: any) => w.visibleOnBooking).map((w: any) => w.key);
  const serviceTitle = fieldTitle((job.services || []).filter((s: any) => serviceKeys.includes(s)), state.fields).join(', ');

  return (
    <div>
      {isMobileOnly ?
        <div>
          <a href="/" style={{ color: COLOR.LINK, fontSize: '16px', fontWeight: 500 }}
            onClick={(e) => {
              e.preventDefault();
              showDrawer(DRAWER.CUSTOMER_VIEW, { customerId: job.customerId, workOrderGuid: job.workOrderParentGuid || job.workOrderGuid })
            }}>{job.customer.displayName}</a> <span></span><StatusBadge status={job.status} />
          <span> </span>
          {((job.services || []).length > 0) && RecentlyCompletedServices(serviceTitle, jobsRecentlyCompleted, serviceKeys, state.fields)}
        </div>
        : <div>
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => showDrawer(DRAWER.CUSTOMER_VIEW, {
              customerId: job?.customer?.id,
              customerGuid: job?.customer?.guid,
              workOrderGuid: job.workOrderParentGuid || job.workOrderGuid
            })}
            data-customer-id={job.customerId}
            data-application-group={job.applicationGroup}
            data-job-guid={job.guid}
            data-work-order-guid={job.workOrderGuid}
            data-parent-work-order-guid={job.workOrderParentGuid}
            data-invoice-guid={job.invoiceGuid}
          >
            {job?.customer?.displayName}
          </a>&nbsp;
          {(isBrowser && handleJobUpdate && isRole(ROLE.ADMIN, ROLE.MANAGER)) &&
            <PopoverDropdownMenu
              renderTitle={(props: any, ref: any) => <IconButton {...props} ref={ref} icon={<MdFlag />} circle style={{ color: (trackingFlags.find((t: any) => t.key === job?.trackingFlag)?.title || '#ccc').toLowerCase(), padding: '4px' }} appearance="link" />}>
              {trackingFlags.map((f: any) =>
                <Dropdown.Item
                  key={f.key}
                  onSelect={() => handleJobUpdate(job.guid, { trackingFlag: f.key }, [])}
                ><MdFlag style={{ color: f.title.toLowerCase() }} /> {f.description || ''}</Dropdown.Item>
              )}
              <Dropdown.Item onSelect={() => handleJobUpdate(job.guid, { trackingFlag: null }, [])}><MdFlag style={{ color: '#ccc' }} /> Unset</Dropdown.Item>
            </PopoverDropdownMenu>
          }
          <span> </span>
          {((job.services || []).length > 0) && RecentlyCompletedServices(serviceTitle, jobsRecentlyCompleted, serviceKeys, state.fields)}
        </div>
      }

      {job?.customer &&
        <div>
          {job.customer.googlePlacesUrl
            ? <a href={`https://www.google.com/maps/dir/?api=1&origin=&destination=${job?.customer?.googlePlacesUrl.split('?')[1].replace('q=', '')}`} target="_blank" rel="noopener noreferrer">{job?.customer?.displayAddress}</a>
            : <a href={`https://www.google.com/maps/dir/?api=1&origin=&destination=${job.customer.customerAddress},${job.customer.displayCity},${job.customer.displayPostalCode}`} target="_blank" rel="noopener noreferrer">{job?.customer?.displayAddress}</a>
          }
        </div>
      }

      <div className={'mt-5'}>
        {!isRole(ROLE.WORKER)
          ? <a style={{ fontWeight: 500 }} onClick={(e) => {
            e.preventDefault();
            showDrawer(DRAWER.WORK_ORDER_FORM, { workOrderGuid: job.parentGuid, action: 'edit' }, () => {
              handleJobRefresh(job.guid);
            });
          }} target="_blank">
            {state.fields.find((s: any) => s.key === job.applicationGroup)?.title}
          </a>
          : <span>{state.fields.find((s: any) => s.key === job.applicationGroup)?.title}</span>
        }

        <>
          <span>&nbsp;&nbsp;&nbsp;</span>

          {job.photoCount > 0 &&
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{job.photoCount} {job.photoCount > 1 ? 'Photos' : 'Photo'}</Tooltip>}>
              <Badge content={job.photoCount} className="email-badge" style={{ width: job.photoCount > 9 ? 28 : 'auto' }}>
                <IconButton
                  style={{ padding: '6px' }}
                  appearance="link"
                  onClick={() => handleShowDrawer(job.guid, 'photos')}
                  icon={<MdCameraAlt />}
                />
              </Badge>
            </Whisper>
          }

          {job.attachmentCount > 0 &&
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{job.attachmentCount} {job.attachmentCount > 1 ? 'Attachments' : 'Attachment'}</Tooltip>}>
              <Badge content={job.attachmentCount} className="email-badge" style={{ width: job.attachmentCount > 9 ? 28 : 'auto' }}>
                <IconButton
                  style={{ padding: '6px' }}
                  appearance="link"
                  onClick={() => showDrawer(DRAWER.ATTACHMENT_UPLOAD_FORM, { resourceGuid: job.guid, titleSuffix: job?.customer?.displayName })}
                  icon={<MdAttachFile />}
                />
              </Badge>
            </Whisper>
          }


          {(job.lastPhotoEmail || []).length > 0 &&
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{(job.lastPhotoEmail || []).length} {(job.lastPhotoEmail || []).length > 1 ? 'Emails' : 'Email'}</Tooltip>}>
              <Badge content={(job.lastPhotoEmail || []).length} className="email-badge">
                <IconButton
                  style={{ padding: '6px' }}
                  appearance="link"
                  onClick={() => handleShowDrawer(job.guid, 'photos')}
                  icon={<MdMail />}
                />
              </Badge>
            </Whisper>
          }

          {((job.lastPhotoEmail || []).length === 0 && job?.emailCount > 0) &&
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{'Email sent out'}</Tooltip>}>
              <Badge content={job?.emailCount} className="email-badge">
                <IconButton
                  style={{ padding: '6px' }}
                  appearance="link"
                  onClick={() => handleShowDrawer(job.guid, 'photos')}
                  icon={<MdMarkEmailRead />}
                />
              </Badge>
            </Whisper>
          }

          {(job.timeCard || []).length > 0 &&
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{job.timeCard.length + 1} Time Cards</Tooltip>}>
              <Badge content={job.timeCard.length + 1} className="email-badge" style={{ width: job.timeCard.length > 9 ? 28 : 'auto' }}>
                <IconButton
                  style={{ padding: '6px' }}
                  appearance="link"
                  onClick={() => showDrawer(DRAWER.TIME_CARD_FORM, { jobGuid: job.guid })}
                  icon={<MdAccessTimeFilled />}
                />
              </Badge>
            </Whisper>
          }

          {job.tag && <Fragment> <span className="label label-info">{job.tag}</span></Fragment>}

          {job.workerId && <Fragment> <span className="label label-info">{state?.userIdMap?.[job.workerId]?.operatorName}</span></Fragment>}
        </>

        {(job.services || []).length > 0 &&
          <div>
            <div style={{ fontWeight: 500 }}>
              {jobTypes.filter((j: any) => job.services.includes(j.key)).map((j: any) => j.title).join(', ')}
              {/* {getFieldPrintable(state.fields, 'work_types', job.services, job.otherServices, ', ')} */}
              {job.startTime && <Fragment> - {format(parseISO(job.startTime), FORMAT.TIME)}</Fragment>}
            </div>
          </div>
        }
      </div>

      {
        jobsColumns.includes('Recurrence') &&
        <div><strong>Recurrence: </strong> {startCase(job?.recurrence)}</div>
      }

      {
        ([].concat(job.attachments || []).concat(job.attachmentsParent || []).length > 0) &&
        <PopoverDropdownMenu
          placement={'bottomStart'}
          renderTitle={() => {
            return <Button style={{ paddingLeft: 0 }} size="sm" appearance="link">Attachments<LegacyArrowDownLineIcon /></Button>;
          }}
        >
          {[].concat(job.attachments || []).concat(job.attachmentsParent || []).map((a: any) =>
            <Dropdown.Item key={a.guid}><a href={`${api.baseUrl}/attachments/download/${a.guid}`} download title={a.fileName} target="_blank">{a.fileName}</a></Dropdown.Item>)
          }
        </PopoverDropdownMenu>
      }

      <div className="mb-5">
        {/* {job.workDescription} */}
        {/* <div>---</div> */}
        {(job.workDescription || '').split(/\n|\n\r/).map((s: string, i: number) =>
          <div key={`description-${i}`}>
            {s.toLowerCase().indexOf('last year') > -1
              ? <Fragment>
                {s.split(/last year/i).map((sl: string, j: number) =>
                  <Fragment key={`description-link-${j}`}>
                    {(j < s.split(/last year/i).length - 1)
                      ? <span>{sl} <Link to={`/app/${getEnv()}/invoice/edit/${job.previousInvoiceGuid}`} onClick={(e: any) => { e.preventDefault(); showDrawer(DRAWER.INVOICE_VIEW, { customerName: job?.customer?.displayName, invoiceGuid: job.previousInvoiceGuid }); }}>last year</Link></span>
                      : <span>{sl}</span>
                    }
                  </Fragment>
                )}
              </Fragment>
              : <div dangerouslySetInnerHTML={{ __html: s.replace(/(\d{3}-\d{3}-\d{4})/g, '<a href="tel:$1">$1</a>') }} />
            }
          </div>
        )}
      </div>

      {
        job.timeInstructions &&
        <div className="mb-5"><strong>Time Instructions: </strong>
          <div dangerouslySetInnerHTML={{ __html: job.timeInstructions.replace(/(\d{3}-\d{3}-\d{4})/g, '<a href="tel:$1">$1</a>') }} />
        </div>
      }

      {
        Object.keys(job.specialInstructions || {}).filter((k: any) => job.specialInstructions?.[k]).length > 0 &&
        <div className="mb-5"><strong>Special Instructions: </strong>
          {job.specialInstructions?.callOnYourWay && <div>Call on your way</div>}
        </div>
      }

      {
        job.customerComments &&
        <div className="mb-5"><strong>Customer Comments: </strong>
          {job.customerComments}
        </div>
      }

      {
        (job.props && job.applicationGroup === 'christmas_lights') &&
        <Fragment>
          <strong style={{ fontWeight: 500 }}>Type of Lights: </strong> {job.props.typeOfLights}
          <span> </span>
          <strong style={{ fontWeight: 500 }}>Strands: </strong> {job.props.numberOfStrands}
          <span> </span>
          <strong style={{ fontWeight: 500 }}>Trees: </strong> {job.props.numberOfTrees}
          <span> </span>
          <strong style={{ fontWeight: 500 }}>Tree Type: </strong> {job.props.treeTypes}
          <span> </span>
          <strong style={{ fontWeight: 500 }}>House Type: </strong> {(job.props.houseType || '-').split(';').join(', ')}
          <span> </span>
          <strong style={{ fontWeight: 500 }}>Strand Ownership: </strong> {startCase(job.props.strandOwnership)}
          <span> </span>
          <strong style={{ fontWeight: 500 }}>Est. Cost: </strong> {moneyFormatter.format(job.workOrderPerCostTypeCost)}
        </Fragment>
      }

      {
        jobsColumns.includes('Contract Notes') &&
        // <div><strong>Contract Notes: </strong> {job?.workOrderParent?.contractNotes}</div>
        <div><strong>Contract Notes: </strong> {job?.contractNotes}</div>
      }

      {
        getEnv() === 'dev' &&
        <div>
          <div><strong>Sort: </strong> {job.sortOrder < 0 ? 0 : job.sortOrder}</div>
          {/* <div><strong>ISO: </strong> {job.startDate}</div> */}
        </div>
      }
    </div >
  );
}

export default CustomerName;
