import { useState, useEffect, useContext, Fragment, useRef } from 'react';

import {
  Button,
  ButtonToolbar,
  Checkbox,
  Input,
  toaster,
  Drawer,
  SelectPicker,
  Whisper,
  Tooltip,
  Loader,
  Form,
  Table,
  Message
} from 'rsuite';

import LegacyCheckCircleIcon from "@rsuite/icons/legacy/CheckCircle";
import LegacyCloseCircleIcon from "@rsuite/icons/legacy/CloseCircle";
import LegacyHelpOIcon from "@rsuite/icons/legacy/HelpO";
import LegacyEyeIcon from "@rsuite/icons/legacy/Eye";
import { ApplicationContext, ResponsiveTable } from 'shared';
import { useApi } from 'lib';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { Editor } from '@tinymce/tinymce-react';
import ResponsiveNav from '@rsuite/responsive-nav';
import startCase from 'lodash/startCase';
import { v4 as uuidv4 } from 'uuid';
import { isMobileOnly } from 'react-device-detect';
import AttachmentForm from 'components/attachment/AttachmentForm';
import { DRAWER, FORMAT, getEnv } from 'lib/env';
import { uniq } from 'lodash';
import { useViewport } from 'shared/ViewportProvider';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import { IDrawerDataProps } from 'shared/ApplicationProvider';
import IconButtonWrapper from 'shared/IconButtonWrapper';
import INTL from 'tenant/intl';
import { GET_TEMPLATES } from 'gql/templates';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { IS_BUGABOO } from 'lib/tenant';
const { Column, HeaderCell, Cell } = Table;

const GET_EMAIL_ACTIVITY = gql`
  query emailActivities($limit: Int!, $offset: Int!, $where: EmailActivitiesWhere, $sort: EmailActivitiesSort) {
    emailActivities(filter: {
      limit: $limit,
      offset: $offset,
      where: $where,
      sort: $sort
    }) {
      edges {
        node {
          guid
          msgId
          customerId
          email
          event
          subject
          createdAt
          displayName
          userId
          reference
          referenceGuid
          applicationEvent
        }
      },
      totalCount
    }
  }
`;

const GET_EMAIL_DETAIL_ACTIVITY = gql`
  query emailActivity($guid: ID!) {
    emailActivity(guid: $guid) {
      guid
      msgId
      customerId
      email
      event
      subject
      createdAt
      displayName
      userId
      reference
      referenceGuid
      resourceHistory
    }
  }
`;

interface EmailDataProps extends IDrawerDataProps {
  /** Email to send out */
  emails?: string[],

  /** Events that need to be emailed out */
  events?: any[]
}

const EmailForm = () => {
  const api = useApi();
  const client = useApolloClient();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { drawerData, showDrawer, onUpdateDrawerCallback }: { drawerData: EmailDataProps, showDrawer: any, onUpdateDrawerCallback: any }
    = useContext(ApplicationContext);
  const { state } = useViewport();
  const { can } = usePrairieAuth();
  const { showMessage } = useContext(ApplicationContext);
  const [selectedEmails, setSelectedEmails] = useState<any>([]);
  const [hasOther, setHasOther] = useState(false);
  const [otherEmails, setOtherEmails] = useState('');
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailId, setEmailId] = useState<number | undefined>(undefined);
  const [emailGuid, setEmailGuid] = useState<string | undefined>(undefined);
  const [emailSubject, setEmailSubject] = useState<string>('');
  const [emailBody, setEmailBody] = useState<string>('');
  const [activeTab, setActiveTab] = useState<any>('send');
  const [emailActivity, setEmailActivity] = useState<Array<any> | undefined>(undefined);
  const [attachments, setAttachments] = useState<Array<any>>([]);
  const [attachmentGroupGuid, setAttachmentGroupGuid] = useState(uuidv4());
  const [recipient, setRecipient] = useState<any>('customer');
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [sendProgress, setSendProgress] = useState<any>([]);
  const [disabledItems, setDisabledItems] = useState<any>([]);
  const [lookupGuid, setLookupGuid] = useState<string | undefined>(undefined);
  const [emailsMissing, setEmailsMissing] = useState(0);
  const templates = useQuery(GET_TEMPLATES, { variables: { offset: 0, limit: 999, order: [{ name: 'ASC' }] } });
  const [defaultFromEmail, setDefaultFromEmail] = useState(state.companies[0]?.email);

  useEffect(() => {
    if (templates?.data) {
      setActiveTab('send');

      let templateKey: string | undefined = undefined;
      switch (drawerData.type) {
        case 'customer':
          templateKey = undefined;
          break;
        case 'site_review':
          templateKey = 'crewsitereviewnotice__0eef18e0__1726508555';
          break;
        case 'job':
          templateKey = 'work_order_confirmation_email';
          break;
        case 'work_order':
          templateKey = 'work_order_confirmation_email';
          break;
        case 'document':
          if (IS_BUGABOO) {
            setDefaultFromEmail('info@bugaboolandscaping.com');
          }

          switch (drawerData.group) {
            case 'concern':
              templateKey = 'concerns_customer_email';
              break;
            case 'contract':
              templateKey = 'contract_email';
              break;
            case 'quote':
              templateKey = 'quoting_customer_email';
              break;
            case 'estimate':
              templateKey = 'estimate_email';
              break;
            default:
              const templateFromServiceField = state.fields.find((f: any) => f.key === (drawerData?.resources?.[0] as any)?.entryType);
              const temp = templates.data.templates.edges.node.find((t: any) => t.guid === templateFromServiceField?.emailTemplateGuid);

              if (temp) {
                templateKey = temp?.key;
              }
          }
          break;
        case 'invoice':
          if ((drawerData?.resources || []).length > 0) {
            const applicationGroup = (drawerData as any).resources[0].applicationGroup;
            const workGroup = state.fields.filter((f: any) => f.parentKey === 'work_groups').find((f: any) => f.key === applicationGroup);

            switch (workGroup?.key) {
              case 'maintenance':
                templateKey = 'maintenance_email';
                break;
              case 'roofing__59634106__1710128534':
              case 'construction':
                templateKey = 'construction_email';
                break;
              case 'christmas_lights':
                templateKey = 'christmas_lights_email';
                break;
              case 'snow_removal':
                templateKey = 'snow_removal_email';
                break;
            }
          }
          break;
      }


      if (drawerData.templateId && drawerData.templateId.toString().length > 0 && drawerData.group) {
        const defaultTemplate = templates.data.templates.edges.node.find((t: any) => drawerData.templateId && (t.id === drawerData.templateId || t.guid === drawerData.templateId));

        if (drawerData.type === 'document') {
          if (defaultTemplate.emailId && defaultTemplate.emailId[drawerData.group]) {
            setEmailId(+defaultTemplate.emailId[drawerData.group]);
          } else {
            setEmailId(+templates.data.templates.edges.node.find((t: any) => t.key === templateKey)?.id);
          }
        } else {
          setEmailGuid(defaultTemplate.guid);
          setEmailId(+defaultTemplate.id);
        }
      } else if (templateKey) {
        const defaultTemplate = templates.data.templates.edges.node.find((t: any) => templateKey && t.key === templateKey);


        if (defaultTemplate) {
          setEmailGuid(defaultTemplate.guid);
          setEmailId(+defaultTemplate.id);
        }
      }

      (async function getEmailActivityData() {
        try {
          setLoading(true);
          const data = await client.query({
            query: GET_EMAIL_ACTIVITY,
            variables: {
              offset: 0,
              limit: 50,
              where: {
                AND: [
                  { referenceGuid: { in: drawerData.resourceGuid ? [drawerData?.resourceGuid] : (drawerData.resources || []).map((r: any) => r.guid) } }
                ]
              },
              sort: {
                createdAt: 'DESC'
              }
            },
            fetchPolicy: 'no-cache'
          });


          setEmailActivity(data.data.emailActivities.edges.node.filter((n: any) => drawerData.applicationEvent ? n.applicationEvent === drawerData.applicationEvent : true));
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      })();

      setAttachmentGroupGuid(uuidv4());
      setSelectedUsers(drawerData?.users || []);
    }
  }, [drawerData?.resources, templates?.data]);

  useEffect(() => {
    (async function getTemplate() {
      try {
        if (emailId) {
          const data = await client.query({
            query: gql`
              query template($id: Int) {
                template(id: $id) {
                  id
                  guid
                  type
                  key
                  text
                  subject
                  attachments {
                    guid
                    fileName
                    filePath
                    fileId
                    fileSize
                    filePath
                    applicationGroup
                  }
                }
              }
            `,
            variables: {
              id: emailId
            },
            fetchPolicy: 'no-cache'
          });

          setEmailId(data.data.template.id);
          setEmailSubject(data.data.template.subject);
          setEmailBody(atob(data.data.template.text));
          setAttachments(data.data.template.attachments.map((a: any) => ({ ...a, protected: true })));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [emailId]);

  useEffect(() => {
    let recipients: any = [];

    for (const r of (drawerData?.resources || [] as any)) {
      for (const u of r.users ? r.users : [r.user]) {
        if (!u) {
          continue;
        }
        
        let contactDetails = [];
        contactDetails = [{
          name: u.operatorName,
          email: u.contactEmail,
          primary: true
        }];

        contactDetails.forEach((c: any) => {
          recipients.push({
            guid: uuidv4(),
            name: c.name,
            email: c.email,
            selected: false,
            resourceGuid: r.guid,
            entryType: startCase(r.entryType || r.applicationGroup),
            title: r.title,
            group: 'crew'
          });
        });
      }
    }

    for (const r of (drawerData?.resources || [] as any)) {
      for (const u of r.customers ? r.customers : [r.customer]) {
        if (!u) {
          continue;
        }
        
        let contactDetails = [];

        if (recipient === 'customer') {
          if (!u.contactDetails) {
            const contactEmail = u.email || '';

            if (contactEmail.trim().length === 0 || (contactEmail.trim().indexOf('@') === -1 && contactEmail.trim().indexOf('.') === -1)) {
              const tempEmailsMissing = emailsMissing + 1;
              setEmailsMissing(tempEmailsMissing);
            }

            contactDetails = contactEmail.split(';').map((e: any) => ({
              primary: true,
              email: e,
              name: u.customerName
            }));
          } else {
            contactDetails = u.contactDetails
              .map((n: any) => ({
                ...n,
                name: n.name.replace('Default', '') || u.customerName
              }));
          }
        }

        contactDetails.forEach((c: any) => {
          recipients.push({
            guid: uuidv4(),
            name: c.name,
            email: c.email,
            selected: c.email.trim().indexOf('@') > -1 && c.email.trim().indexOf('.') > -1,
            resourceGuid: r.guid,
            entryType: startCase(r.entryType || r.applicationGroup),
            title: r.title,
            group: 'customer'
          });
        });
      }
    }

    recipients = recipients.filter((r: any) => (r.email || '').length > 0)

    setDisabledItems(recipients.filter((c: any) =>
      !c.email || c.email.length === 0 || (c.email.trim().indexOf('@') === -1 && c.email.trim().indexOf('.') === -1)
    ).map((c: any) => c.guid));

    setSelectedEmails(recipients);
    setOtherEmails('');
    setHasOther(false);
    setSendingEmail(false);
  }, [drawerData?.resources, recipient]);

  useEffect(() => {
    const sendTo = [];

    for (const c of selectedEmails.filter((c: any) => c.selected)) {
      sendTo.push(c);
    }

    for (const email of (otherEmails || '').split(',').map((o: any) => o.trim()).filter((o: any) => o.length > 0)) {
      sendTo.push({
        ...selectedEmails[0],
        email,
        guid: uuidv4()
      });
    }

    setSendProgress(sendTo);
  }, [otherEmails, selectedEmails, recipient]);

  const handleSendEmail = async () => {
    setSendingEmail(true);

    for (const c of sendProgress) {
      try {
        const response = await api.post(`/emails/send/${drawerData.type}/${c.resourceGuid}`, {
          templateSubject: emailSubject,
          templateText: emailBody,
          guid: c.resourceGuid,
          attachmentGroupGuid,
          attachments,
          selectedEmails: c.email,
          applicationEvent: drawerData?.applicationEvent,
          fromEmail: defaultFromEmail
        });

        setSendProgress(sendProgress.map((r: any) => {
          if (r.guid === c.guid) {
            r.response = response;
          }
          return r;
        }));
      } catch (err) {
        setSendProgress(sendProgress.map((r: any) => {
          if (r.guid === c.guid) {
            r.response = err;
          }
          return r;
        }));
      }
    }


    setTimeout(() => {
      const errors = sendProgress.filter((p: any) => p?.response?.code === 500 || p?.response?.isAxiosError);
      setSendingEmail(false);

      if (errors.length === 0) {
        toaster.push(<Message type="success" showIcon closable>Emails sent</Message>);
        setSendProgress([]);
        showDrawer(undefined);
        onUpdateDrawerCallback();
      } else {
        toaster.push(
          <Message type="error" showIcon closable>Emails sent but there were some errors. Double check customer records and try again.</Message>
        );
      }
    }, 1000);
  }

  const outOfDate = (resources: any) => {
    return resources
      .map((r: any) => {
        const template = (templates?.data.templates.edges.node || []).find((t: any) => t.id === r.templateId);
        if (template && template.latestVersionId !== r.templateVersionId) {
          return r;
        }

        return undefined;
      })
      .filter((r: any) => r);
  }

  return (
    <Drawer open onClose={() => showDrawer(undefined)} size={'lg'} full={isMobileOnly}>
      <Drawer.Header>
        <Drawer.Title>Email {startCase(drawerData?.type)}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <div ref={container}>
          <ResponsiveNav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab}>
            <ResponsiveNav.Item eventKey="send">Send</ResponsiveNav.Item>
            <ResponsiveNav.Item eventKey="history">History</ResponsiveNav.Item>
          </ResponsiveNav>

          {drawerData.type === 'notification' &&
            <Message type="info" className="mt-12">
              <div>
                Notification email will notify all customers selected:<br />
                - <strong>DO NOT</strong> use this feature to send marketing emails.<br />
                - Large amount of emails may take longer to process, wait for process to finish.<br />
                - Template tokens are not currently supported in notification emails.
              </div>
            </Message>
          }

          <div className="mt-6">
            {(activeTab === 'send' && drawerData?.resources && templates?.data) &&
              <>
                {((sendingEmail && sendProgress.length > 0) || sendProgress.filter((s: any) => s?.response?.code === 500 || s?.response?.isAxiosError).length > 0)
                  ? <>
                    <Message className="mb-12" type={'info'}>Please wait while all the emails are sent, this window will close automatically if all emails are sent.</Message>
                    {uniq(sendProgress.map((s: any) => s.group))
                      .map((c: any) => <div key={c}>
                        <div className="mb-12">
                          <strong>{startCase(c)}</strong>
                          {sendProgress
                            .filter((s: any) => s.group === c)
                            .map((c: any) =>
                              <div key={c.guid} style={{ clear: 'both' }} className="mb-6">
                                {c.response
                                  ? c?.response?.success
                                    ? <span>
                                      <LegacyCheckCircleIcon style={{ color: '#5cb85c' }} /> {c.email}
                                    </span>
                                    : <span>
                                      <LegacyCloseCircleIcon style={{ color: '#d9534f' }} /> {c.email} - {c.response.message}
                                    </span>
                                  : <span>
                                    <Loader size="xs" style={{ float: 'left', marginRight: '12px' }} /> {c.email}
                                  </span>
                                }
                              </div>
                            )
                          }
                        </div>
                      </div>)
                    }
                  </>
                  : <Form>
                    <Form.Group>
                      <Form.ControlLabel>From:</Form.ControlLabel>
                      <SelectPicker
                        cleanable={false}
                        searchable={false}
                        block
                        onChange={setDefaultFromEmail}
                        value={defaultFromEmail}
                        data={[
                          { label: state.companies[0]?.email, value: state.companies[0]?.email }
                        ].concat(IS_BUGABOO ? [
                          { label: 'info@bugaboolandscaping.com', value: 'info@bugaboolandscaping.com' },
                          { label: 'treecare@bugaboolandscaping.com', value: 'treecare@bugaboolandscaping.com' }
                        ]: [])}
                      />
                    </Form.Group>
                    <Form.Group>
                      {(drawerData.type === 'document' && drawerData.resources.length > 0 && outOfDate(drawerData.resources).length > 0) &&
                        <div className="mb-8 mt-8">
                          <Message type='error'>
                            <div>
                              {INTL.DOCUMENTS} {outOfDate(drawerData.resources).map((d: any) =>
                                <>
                                  {can('document:update')
                                    ? <a href="/" onClick={(e: any) => {
                                      e.preventDefault();
                                      showDrawer(DRAWER.DOCUMENT_FORM, { action: 'edit', documentGuid: d.guid });
                                    }}>{d.customer.displayName}, </a>
                                    : <span>{d.customer.displayName}, </span>
                                  }
                                </>
                              )
                              } are using an older template version. Newer versions are available.
                            </div>
                          </Message>
                        </div>
                      }

                      <Form.ControlLabel>
                        Recipients: 
                          <span> </span><a href="/" className='font-normal' onClick={(e: any) => { e.preventDefault(); setSelectedEmails(selectedEmails.map((e: any) => { e.selected = true; return e; } )) }}>All</a>
                          <span> | </span><a href="/" className='font-normal' onClick={(e: any) => { e.preventDefault(); setSelectedEmails(selectedEmails.map((e: any) => { e.selected = false; return e } )) }}>None</a>
                          <span> | </span><a href="/" className='font-normal' onClick={(e: any) => { e.preventDefault(); setSelectedEmails(selectedEmails.map((e: any) => { e.selected = e.group === 'crew'; return e; } )) }}>{INTL.CREW}</a>
                          <span> | </span><a href="/" className='font-normal' onClick={(e: any) => { e.preventDefault(); setSelectedEmails(selectedEmails.map((e: any) => { e.selected = e.group === 'customer'; return e; } )) }}>{INTL.CLIENT}</a>
                      </Form.ControlLabel>

                      <div><span>{INTL.CREW}</span></div>
                      {selectedEmails.filter((s: any) => s.group === 'crew').map((e: any) =>
                        <div key={e.guid}>
                          <Checkbox value={e.email} checked={e.selected} onChange={(val: any, checked: any, event: any) => {
                            setSelectedEmails(selectedEmails.map((s: any) => {
                              if (s.group === 'crew' && s.email === val) {
                                s.selected = !s.selected;
                              }

                              return s;
                            }));
                          }}>
                            {e.email.trim().length === 0 || (e.email.trim().indexOf('@') === -1 && e.email.trim().indexOf('.') === -1)
                              ? `${e.name} - (email not entered)`
                              : `${e.name} - ${e.email.split(';').filter((e: any) => e).join(', ')}`}
                          </Checkbox>
                        </div>
                      )}

                      <div><span>{INTL.CLIENT}</span></div>
                      {selectedEmails.filter((s: any) => s.group === 'customer').map((e: any) =>
                        <div key={e.guid}>
                          <Checkbox value={e.email} checked={e.selected} onChange={(val: any, checked: any, event: any) => {
                            setSelectedEmails(selectedEmails.map((s: any) => {
                              if (s.group === 'customer' && s.email === val) {
                                s.selected = !s.selected;
                              }
                              
                              return s;
                            }));
                          }}>
                            {e.email.trim().length === 0 || (e.email.trim().indexOf('@') === -1 && e.email.trim().indexOf('.') === -1)
                              ? `${e.name} - (email not entered)`
                              : `${e.name} - ${e.email.split(';').filter((e: any) => e).join(', ')}`}
                          </Checkbox>
                        </div>
                      )}

                      <div><span>Other:</span></div>
                      <div>
                        <Input type="text" onChange={setOtherEmails} value={otherEmails} />
                        <Form.HelpText>Selected emails will be included in the email. Separate multiple addresses using a comma.</Form.HelpText>
                      </div>
                      {/* <CheckTree
                        className={'mt-6'}
                        value={selectedEmails.filter((s: any) => s.selected).map((s: any) => s.guid)}
                        disabledItemValues={disabledItems}
                        // renderMenuGroup={(_title: any, item: any) => {
                        //   return (
                        //     <div>
                        //       {item.children[0].name} - {item.children[0].entryType} {item.children[0].title}
                        //     </div>
                        //   )
                        // }}
                        data={selectedEmails.map((e: any) => ({
                          ...e,
                          value: e.guid,
                          label: e.email.trim().length === 0 || (e.email.trim().indexOf('@') === -1 && e.email.trim().indexOf('.') === -1)
                            ? `${e.name} - (email not entered)`
                            : `${e.name} - ${e.email.split(';').filter((e: any) => e).join(', ')}`
                        }))}
                        onChange={(val: any) => {
                          setSelectedEmails(selectedEmails.map((c: any) => {
                            c.selected = val.includes(c.guid);
                            return c;
                          }));
                        }}
                      /> */}


                      {emailsMissing > 0 &&
                        <div className="text-danger">{emailsMissing} {INTL.CUSTOMERS.toLowerCase()} missing emails.</div>
                      }
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Template:</Form.ControlLabel>
                      <SelectPicker
                        container={() => container && container.current}
                        value={emailId}
                        cleanable={false}
                        block
                        data={(templates?.data.templates.edges.node.filter((t: any) => t.deletedAt === null || t.id === emailId) || []).filter((t: any) => t.type === 'email' && (t.isVisible || t.id === emailId)).map((d: any) => ({
                          label: d.name,
                          value: d.id
                        }))}
                        onChange={(val: any) => {
                          const temp = (templates?.data.templates.edges.node || []).find((t: any) => +t.id === +val);
                          setEmailId(temp.id);
                          setEmailGuid(temp.guid);
                        }}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel className="required">Subject:</Form.ControlLabel>
                      <Input
                        value={emailSubject}
                        onChange={setEmailSubject}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>
                        <Whisper placement="top" speaker={<Tooltip>Content is generated from the template and can be edited before sending</Tooltip>}>
                          <span>Template: <span className="text-danger">*</span> <LegacyHelpOIcon /></span>
                        </Whisper>
                      </Form.ControlLabel>
                      <Editor
                        tinymceScriptSrc='/tinymce/tinymce.min.js'
                        licenseKey='gpl'
                        // plugins={['pagebreak advlist autolink link image lists hr anchor pagebreak', 'visualblocks code media nonbreaking', 'table directionality template paste']}
                        toolbar='tokenbutton | bold italic underline strikethrough | justifyleft justifycenter justifyright justifyfull | cut copy paste pastetext pasteword | bullist numlist | outdent indent | link unlink image table cleanup code | tablecontrols hr pagebreak'
                        value={emailBody}
                        apiKey='pec9l8zp8dpcx14izwyhwnxktzs2wo8p64z6vxb4a7nwpg54'
                        init={{
                          promotion: false,
                          license_key: 'gpl' as any,
                          statusbar: false,
                          height: 300,
                          menubar: 'view format table tools',
                          branding: false,
                          content_style: "body { font-size: 14px; }"
                        }}
                        onEditorChange={setEmailBody}
                      />
                      <Form.HelpText>For full list of variable and to edit template <a href={`/app/${getEnv()}/templates/edit/email/${emailGuid}`} target="_blank">click here</a>.</Form.HelpText>
                    </Form.Group>

                    <Form.Group>
                      <Form.ControlLabel>Attachments (5MB LIMIT):</Form.ControlLabel>
                      <AttachmentForm
                        destination={'email-attachments'}
                        referenceGuid={attachmentGroupGuid}
                        attachments={attachments}
                        listType="simple-form"
                        showSize
                        onChange={(val: any) => setAttachments(attachments)}
                        limitAlert={5242880}
                      />
                    </Form.Group>
                  </Form>
                }
              </>
            }

            {(activeTab === 'history') &&
              <div>
                {loading
                  ? <Loader content="Loading..." />
                  : <Fragment>
                    {(!emailActivity || emailActivity.length === 0)
                      ? <p>No email activity found. Older emails do not have this feature.</p>
                      : <ResponsiveTable
                        data={emailActivity}
                        autoHeight
                        height={40}
                        wordWrap={false}
                        loading={loading}>
                        <Column flexGrow={1}>
                          <HeaderCell>Email</HeaderCell>
                          <Cell dataKey="email" />
                        </Column>
                        <Column flexGrow={1}>
                          <HeaderCell>Type</HeaderCell>
                          <Cell>{(row: any) => startCase(row.reference)}</Cell>
                        </Column>
                        <Column flexGrow={1}>
                          <HeaderCell>Subject</HeaderCell>
                          <Cell>{(row: any) => startCase(row.event) + ' - ' + startCase(row.subject)}</Cell>
                        </Column>
                        <Column flexGrow={1}>
                          <HeaderCell>Date</HeaderCell>
                          <Cell>{(row: any) => format(parseISO(row.createdAt), FORMAT.DATE_TIME)}</Cell>
                        </Column>
                        <Column>
                          <HeaderCell>&nbsp;</HeaderCell>
                          <Cell className="link-group text-right">
                            {(row: any) => <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>View</Tooltip>}>
                              <IconButtonWrapper
                                onClick={async () => {
                                  setLookupGuid(row.guid);
                                  const data = await client.query({
                                    query: GET_EMAIL_DETAIL_ACTIVITY,
                                    variables: {
                                      guid: row.guid
                                    },
                                    fetchPolicy: 'no-cache'
                                  });

                                  if ((data.data.emailActivity?.resourceHistory?.events || []).length > 0) {
                                    const message = JSON.parse(data.data.emailActivity?.resourceHistory?.events[0].message);
                                    showMessage(<div dangerouslySetInnerHTML={{ __html: message.html }} />, 'Email Preview');
                                  } else {
                                    showMessage(<div>Preview unavailable. This feature is available on emails sent after Nov 3rd.</div>, 'Email Preview');
                                  }
                                  setLookupGuid(undefined);
                                }}
                                appearance="link"
                                icon={lookupGuid === row.guid ? <Loader size="xs" /> : <LegacyEyeIcon />}
                              />
                            </Whisper>}
                          </Cell>
                        </Column>
                      </ResponsiveTable>
                    }
                  </Fragment>
                }
              </div>
            }
          </div>
        </div>
      </Drawer.Body>
      <Drawer.Actions>
        <ButtonToolbar>
          {sendProgress.filter((s: any) => s?.response?.code === 500 || s?.response?.isAxiosError).length > 0 && <Button 
            size="sm"
            onClick={() => setSendProgress(sendProgress.map((s: any) => { delete s.response; return s; }))}
            appearance="primary">Try Again</Button>
          }
          {(activeTab === 'send' && sendProgress.filter((s: any) => s?.response?.code === 500 || s?.response?.isAxiosError).length === 0) && <Button
            size="sm"
            onClick={handleSendEmail}
            appearance="primary"
            disabled={sendProgress.length === 0}
            loading={sendingEmail}>Send</Button>
          }
          <Button size="sm" onClick={() => showDrawer(undefined)} appearance="subtle" disabled={sendingEmail}>Close</Button>
        </ButtonToolbar>
      </Drawer.Actions>
    </Drawer>
  );
}

export {
  EmailForm
}
