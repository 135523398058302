import { ApolloClient, ApolloLink, InMemoryCache, concat, createHttpLink } from '@apollo/client';
import { buildAxiosFetch } from "@lifeomic/axios-fetch";
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';
import { IS_LOCALHOST } from './tenant';
import { onError } from "@apollo/client/link/error";
axiosRetry(axios, { retries: 5 });

const useAppApolloClient = () => {
  let baseUrl = `https://${window.location.host}/api`;

  if (IS_LOCALHOST) {
    baseUrl = `https://assiniboine.prairiehq.net/api`;
  }

  const instance: any = axios.create({
    baseURL: baseUrl,
    withCredentials: true
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Location: ${window?.location?.pathname}`
        )
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    connectToDevTools: (import.meta as any).env?.VITE_APP_ENV === 'development',
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache'
      },
      query: {
        fetchPolicy: 'no-cache'
      },
      mutate: {
        fetchPolicy: 'no-cache'
      }
    },
    link: ApolloLink.from([
      new ApolloLink((operation, forward) => {
        return forward(operation).map((response: any) => {
          return response;
        });
      }),
      errorLink,
      createHttpLink({
        uri: (operation) => {
          instance.defaults.headers.common['x-request-id'] = uuidv4();
          return `${baseUrl}?operation=${encodeURIComponent(operation.operationName)}`
        },
        credentials: 'include',
        fetch: buildAxiosFetch(instance) as any
      })
    ])
  });

  return client;
};

export default useAppApolloClient;
