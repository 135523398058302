import { useContext, useEffect, useState } from 'react';
import { Button, Drawer, Grid, Row, Col, Table, Whisper, Tooltip, DatePicker, Message, SelectPicker, toaster } from 'rsuite';
import { isMobileOnly } from 'react-device-detect';
import { useViewport } from 'shared/ViewportProvider';
import { ApplicationContext } from 'shared/ApplicationProvider';
import IconButtonWrapper from 'shared/IconButtonWrapper';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { GET_JOB } from 'gql/jobs';
import { v4 as uuidv4 } from 'uuid';
import { format, parseISO } from 'date-fns';
import INTL from 'tenant/intl';
import { ResponsiveTable } from 'shared';
import { FORMAT } from 'lib/env';

const { Column, HeaderCell, Cell } = Table;

const TimeCardForm = () => {
  const { state } = useViewport();
  const client = useApolloClient();
  const {
    drawerData,
    showDrawer,
    showConfirmation,
    showError
  } = useContext(ApplicationContext);
  const [timeCard, setTimeCard] = useState<any>([]);
  const { loading, error, data } = useQuery(GET_JOB, { variables: { guid: drawerData.jobGuid } });

  useEffect(() => {
    if (data?.job) {
      setTimeCard([{
        guid: data.job.guid,
        timeOnSite: data.job.timeOnSite,
        timeFromSite: data.job.timeFromSite,
        user: data?.job?.workerId ? state?.userIdMap?.[data?.job?.workerId]?.operatorName : state?.userIdMap?.[data?.job?.userId]?.operatorName,
        userId: data?.job?.userId,
        userGuid: state.users.find((u: any) => u.id === data?.job?.userId)?.guid,
        default: true
      }]
        .concat((data?.job.timeCard || []))
        .map((d: any) => ({
          ...d,
          timeOnSite: d.timeOnSite ? parseISO(d.timeOnSite) : undefined,
          timeFromSite: d.timeFromSite ? parseISO(d.timeFromSite) : undefined,
          userId: state.users.find((u: any) => u.guid === d.userGuid)?.id
        })));
    } else {
      setTimeCard([{
        guid: uuidv4(),
        timeOnSite: undefined,
        timeFromSite: undefined,
        user: undefined,
        userId: undefined,
        default: true
      }]);
    }
  }, [data]);

  const addRow = () => {
    setTimeCard(([] as any).concat(timeCard).concat({
      guid: uuidv4(),
      timeOnSite: undefined,
      timeFromSite: undefined,
      user: undefined,
      userId: undefined,
      default: false
    }));
  }

  const removeRow = async (guid: string) => {
    try {
      const response: any = await client.mutate({
        mutation: gql`
          mutation deleteTimeCard($guid: ID!) {
            deleteTimeCard(guid: $guid) {
              code
              success
              message
            }
          }
        `,
        variables: {
          guid
        }
      });

      if (response.data.deleteTimeCard.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.deleteTimeCard.message}</Message>
        );
        setTimeCard(timeCard.filter((t: any) => t.guid !== guid));
      } else {
        showError(response);
      }
    } catch (err) {
      showError(err);
    }
  }

  const handleUpdate = async (guid: string, input: any) => {
    try {
      const response: any = await client.mutate({
        mutation: gql`
          mutation upsertTimeCard($input: UpsertTimeCardInput) {
            upsertTimeCard(input: $input) {
              code
              success
              message
              result
            }
          }
        `,
        variables: {
          input: { ...input, guid }
        }
      });

      if (response.data.upsertTimeCard.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertTimeCard.message}</Message>
        );

        setTimeCard(timeCard.map((t: any) => {
          if (t.guid === response.data.upsertTimeCard.result.guid) {
            return {
              ...t,
              timeOnSite: response.data.upsertTimeCard.result.timeOnSite ? parseISO(response.data.upsertTimeCard.result.timeOnSite) : undefined,
              timeFromSite: response.data.upsertTimeCard.result.timeFromSite ? parseISO(response.data.upsertTimeCard.result.timeFromSite) : undefined,
              userId: state.users.find((u: any) => u.guid === response.data.upsertTimeCard.result?.userGuid)?.id
            }
          }

          return t;
        }))
      } else {
        showError(response);
      }
    } catch (err) {
      showError(err);
    }
  }

  return (
    <Drawer open onClose={() => showDrawer(undefined)} backdrop="static" size={isMobileOnly ? 'full' : 'sm'}>
      <Drawer.Header><Drawer.Title>Time Card: {data?.job?.customer?.displayName}</Drawer.Title></Drawer.Header>
      <Drawer.Body>
        <Grid fluid>
          <Message type="info">All entries are auto saved</Message>
          <ResponsiveTable data={timeCard} autoHeight style={{ width: '100%' }}>
            <Column flexGrow={1}>
              <HeaderCell>Onsite</HeaderCell>
              <Cell className='link-group'>
                {(row: any) => <>
                  {row.default
                    ? <div className="text-left p-5">{row.timeOnSite ? format(row.timeOnSite, FORMAT.TIME24) : 'NA'}</div>
                    : <DatePicker
                      size="sm"
                      block
                      cleanable={false}
                      value={row.timeOnSite}
                      format={'HH:mm'}
                      onChange={(date: any) => handleUpdate(row.guid, { jobGuid: drawerData.jobGuid, timeOnSite: date ? date.toISOString() : date })}
                    />
                  }
                </>
                }
              </Cell>
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Offsite</HeaderCell>
              <Cell className="link-group">
                {(row: any) => <>
                  {row.default
                    ? <div className="text-left p-5">{row.timeFromSite ? format(row.timeFromSite, FORMAT.TIME24) : 'NA'}</div>
                    : <DatePicker
                      size="sm"
                      block
                      cleanable={false}
                      value={row.timeFromSite}
                      format={'HH:mm'}
                      onChange={(date: any) => handleUpdate(row.guid, { jobGuid: drawerData.jobGuid, timeFromSite: date ? date.toISOString() : date })}
                    />
                  }
                </>}
              </Cell>
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>{INTL.CREW}</HeaderCell>
              <Cell className="link-group">
                {(row: any) => <>
                  {row.default
                    ? <div className="text-left p-5">{state.users.find((u: any) => u.guid === row.userGuid)?.operatorName}</div>
                    : <SelectPicker
                      block
                      value={row.userId}
                      groupBy="role"
                      size='sm'
                      data={state.users}
                      onSelect={(val: any) => {
                        handleUpdate(row.guid, { jobGuid: drawerData.jobGuid, userGuid: state.users.find((u: any) => u.id === val).guid });
                      }}
                    />
                  }
                </>}
              </Cell>
            </Column>
            <Column>
              <HeaderCell>&nbsp;</HeaderCell>
              <Cell className="link-group text-right">
                {(row: any) => <>
                  <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Add</Tooltip>}>
                    <IconButtonWrapper
                      onClick={addRow}
                      appearance="link"
                      icon={'add'}
                    />
                  </Whisper>

                  <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{row.default ? 'Cannot remove default entry' : 'Remove'}</Tooltip>}>
                    <IconButtonWrapper
                      disabled={row.default}
                      onClick={() => {
                        showConfirmation(
                          <p>Are you sure you want to remove this time entry?</p>,
                          'Remove Time Entry',
                          () => removeRow(row.guid)
                        );
                      }}
                      appearance="link"
                      icon={'delete'}
                    />
                  </Whisper>
                </>}
              </Cell>
            </Column>
          </ResponsiveTable>
        </Grid>
      </Drawer.Body>
      <Drawer.Footer>
        <Button size="sm" onClick={() => showDrawer(undefined)} appearance={'subtle'}>Close</Button>
      </Drawer.Footer>
    </Drawer>
  );
}

export {
  TimeCardForm
}
