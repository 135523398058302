import { gql, useApolloClient } from '@apollo/client';
import { getFilter } from 'lib/helpers/filter';
import { useContext, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, Drawer, Checkbox, SelectPicker } from 'rsuite';
import { ApplicationContext } from 'shared';
import { useFilteredParams } from 'shared/FilterProvider';
import { useViewport } from 'shared/ViewportProvider';
import { v4 as uuidv4 } from 'uuid';
import store from 'lib/storage';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from 'lib';
import { IS_ASSINIBOINE, IS_KALADI_KITCHENS } from 'lib/tenant';
import queryString from 'query-string';
import { getSort } from 'lib/helpers/sort';

const queryParams = queryString.parse(window.location.search);

const Exporter = () => {
  const client = useApolloClient();
  const api = useApi();
  const { state } = useViewport();
  const { drawerData, showDrawer } = useContext(ApplicationContext);
  const { params } = useFilteredParams(drawerData.paramsId);
  const [exportType, setExportType] = useState<any>(IS_ASSINIBOINE ? 'detailed' : 'default');
  const [downloading, setDownloading] = useState(false);
  const [exportColumns, setExportColumns] = useState<string[]>(drawerData.selectColumns || []);
  const [includeMissing, setIncludeMissing] = useState(false);

  useEffect(() => {
    (async function runExport() {
      if ((drawerData.columns || []).length === 0) {
        await handleSubmit();
        showDrawer(undefined);
      }
    })();
  }, [drawerData]);

  const handleSubmit = async () => {
    setDownloading(true);

    let sort: any = getSort(drawerData.paramsId);
    
    if (Object.keys(sort).length === 0) {
      let sortObject = store.get(`${drawerData.paramsId}-sort`);
      sort = sortObject?.sortColumn ? { [sortObject.sortColumn]: sortObject.sortType.toUpperCase() } : {};
    }
    
    const res = await client.query({
      query: gql`
      query export($guid: String!, $name: String!, $params: JSON) {
        export(guid: $guid, name: $name, params: $params) {
          code,
          message,
          guid
        }
      }
    `,
      variables: {
        guid: uuidv4(),
        name: drawerData.group,
        params: {
          columns: exportColumns,
          filter: getFilter(drawerData.group, { 
            ...params, 
            textFilter: drawerData?.extraParams?.textFilter || '',
            viewPortCustomers: drawerData?.extraParams?.viewPortCustomers || [],
            includeMissing,
            ...(drawerData.extraFilter || {}) 
          }, state.profile),
          sort,
          type: exportType,
          extraParams: { ...drawerData?.extraParams, includeMissing }
        }
      },
      fetchPolicy: 'no-cache'
    });

    setDownloading(false);
    const uri = await api.download(`/attachments/exports/download/${res.data.export.guid}/${drawerData.group}/xlsx`);
    window.location.href = uri;
  }

  return (
    <Drawer open size={'xs'} full={isMobileOnly} onClose={() => showDrawer(undefined)}>
      <Drawer.Header>
        <Drawer.Title>Export View</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {drawerData.group === 'workOrders' &&
          <div className="mb-12">
            <p>Export:</p>
            <SelectPicker
              block
              value={exportType}
              onChange={setExportType}
              searchable={false}
              cleanable={false}
              data={[
                { value: 'default', label: 'Default' },
                { value: 'detailed', label: 'Detailed' }
              ]}
            />
          </div>
        }

        {(IS_KALADI_KITCHENS && drawerData.group === 'documents') &&
          <div>
            <div><label>Export missing documents:</label></div>
            <Checkbox checked={includeMissing} onChange={() => setIncludeMissing(!includeMissing)}>Include Missing</Checkbox>
          </div>
        }

        {(drawerData.columns || []).length > 0 &&
          <>
            <div><label>Select columns to export:</label> (
              <Link to="/" onClick={(e: any) => { e.preventDefault(); setExportColumns(drawerData.columns); }}>All</Link>
              <span> | </span><Link to="/" onClick={(e: any) => { e.preventDefault(); setExportColumns([]); }}>None</Link>
              ):
            </div>
          </>
        }
        {drawerData.columns
          ? <div>
            {drawerData.columns.map((col: any) =>
              <div key={col}>
                <Checkbox
                  onChange={(val: any) => {
                    const tmp: any = [].concat(exportColumns as any);

                    if (exportColumns.includes((val))) {
                      tmp.splice(tmp.indexOf(val), 1);
                      setExportColumns(tmp);
                    } else {
                      tmp.push(val);
                      setExportColumns(tmp);
                    }
                  }}
                  value={col}
                  checked={exportColumns.includes(col)}
                >{col}</Checkbox>
              </div>
            )}
          </div>
          : <div>Please wait for export to generate</div>
        }
      </Drawer.Body>
      <Drawer.Actions>
        <Button size="sm" onClick={handleSubmit} appearance="primary" loading={downloading}>Export</Button>
        <Button size="sm" onClick={() => showDrawer(undefined)} disabled={downloading} appearance="subtle">Close</Button>
      </Drawer.Actions>
    </Drawer>
  );
}

export {
  Exporter
}
