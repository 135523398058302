import { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { ViewDesktop, ViewMobile, ClockInOut } from './components';
import {
  toaster,
  Button,
  ButtonToolbar,
  Checkbox,
  Col,
  DatePicker,
  DOMHelper,
  Message,
  Modal,
} from 'rsuite';

import { Pagination, Filter, ApplicationContext } from 'shared';
import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import { GET_JOBS, GET_JOBS_RECENTLY_COMPLETED, updateJob } from '../../gql/jobs';
import { useFilteredParams } from 'shared/FilterProvider';
import { useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import snakeCase from 'lodash/snakeCase';
import { rescheduleJob } from 'lib/helpers';
import { FORMAT, getEnv, getSortType, ROLE, setHeaderTitle, STATUS } from 'lib/env';
import PhotoUploader from './components/PhotoUploader';
import { getFilter } from 'lib/helpers/filter';
import { isMobileOnly, isBrowser } from 'react-device-detect';
import Notes from './components/Notes';
import { IS_BUGABOO, IS_KALADI_KITCHENS } from 'lib/tenant';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import { useViewport } from 'shared/ViewportProvider';
import { camelCase, omit } from 'lodash';
import { ExportField, MapToggleField } from 'components/form';
import { getSort } from 'lib/helpers/sort';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { format } from 'date-fns';
import JobsReport from './JobsReport';
import INTL from 'tenant/intl';
import { parseISO } from 'lib/date';
import { useCompany } from 'lib/hooks';
import store from 'lib/storage';
import { ColumnChooserField } from 'components/form/FilterFields';
import { v4 as uuidv4 } from 'uuid';
import { useLogger } from 'lib/logger';

interface IJobsList {
  reports: Array<string>
}

const JobsList = ({ reports }: IJobsList) => {
  setHeaderTitle('Jobs');
  const logger = useLogger();
  const filterKey: any = 'jobs' + (window.location.pathname.includes('on-demand') ? 'ondemand' : '');
  const client = useApolloClient();
  const match: any = useRouteMatch();
  const company = useCompany();
  const { isRole } = usePrairieAuth();
  const { state } = useViewport();
  const { showError, showDrawer } = useContext(ApplicationContext);
  const { params }: any = useFilteredParams(filterKey);
  const [jobs, setJobs] = useState<any>([]);
  const [jobsRecentlyCompleted, setJobsRecentlyCompleted] = useState<any>([]);
  const [status, setStatus] = useState(STATUS.LOADING);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [showMyBookings, setShowMyBookings] = useLocalStorage('showMyBookings', false);
  const [activeJob, setActiveJob] = useState<any | undefined>(undefined);
  const [activeDrawer, setActiveDrawer] = useState<string | undefined>(undefined);
  const [record, setRecord] = useState<any>(undefined);
  const [filter, setFilter] = useState<any>(params);
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const [workOrderEvent, setWorkOrderEvent] = useState<any>({
    show: false,
    startEndDate: undefined,
    action: 'add',
    guid: undefined
  });
  const [recordsPerPage, setRecordsPerPage] = useLocalStorage('recordsPerPage', 20);
  const [clockInOutJob, setClockInOutJob] = useState<any>(undefined);
  const [clockInOutMode, setClockInOutMode] = useState<string | undefined>(undefined);
  const [sort, setSort] = useLocalStorage<any>(`${filterKey}-sort`, { sortColumn: '', sortType: undefined });
  const tableDataRef = useRef();
  const [getJob, getJobResult] = useLazyQuery(GET_JOBS, { fetchPolicy: 'network-only' });
  const [confirmedOutsideDateRange, setConfirmedOutsideDateRange] = useState(false);

  useEffect(() => {
    setOffset(0);
  }, [filter]);

  useEffect(() => {
    if (getJobResult?.data?.jobs?.totalCount === 1) {
      setJobs(jobs.map((j: any) => {
        if (j.guid === getJobResult?.data?.jobs?.edges?.node[0]?.guid) {
          return { ...j, ...getJobResult?.data?.jobs?.edges?.node[0] };
        }

        return j;
      }));
    }
  }, [getJobResult.loading]);

  useEffect(() => {
    let order = JSON.parse(store.session.get(`${filterKey}-order`, '[]'));
    if (order.length === 0) {
      order = getSort(filterKey);
    }

    if (filter.range.length > 0) {
      (async function getJobs() {
        try {
          let whereFilter: any = getFilter(filterKey, filter, state.profile);

          if (queryString.parse(window.location.search).query && queryString.parse(window.location.search).query?.includes('jobGuid:')) {
            whereFilter = { AND: [{ guid: { is: (queryString.parse(window.location.search).query as any)?.toString().replace('jobGuid:', '') } }] };
          }

          if (window.location.pathname.includes('on-demand')) {
            whereFilter.AND.push({ status: { is: 'scheduled' } });
            whereFilter.AND.push({ recurrence: { is: 'ondemand' } });
          }

          setStatus(STATUS.LOADING);
          setJobs([]);

          const data = await client.query({
            query: GET_JOBS,
            variables: {
              offset: offset < 0 ? 0 : offset,
              limit: recordsPerPage,
              where: whereFilter,
              order: (sort.sortType !== undefined && sort?.sortColumn !== 'sortDate') ? [{ [sort.sortColumn]: sort.sortType.toUpperCase() }] : order
            },
            fetchPolicy: 'no-cache'
          });

          const results = data.data.jobs.edges.node
            .filter((n: any) => (showMyBookings && isRole(ROLE.CLIENT, ROLE.CLEANER)) ? n.userId === state.profile.id : true)
            .filter((n: any) => IS_KALADI_KITCHENS ? n.status !== 'canceled' : true);

          setJobs(results);
          setTotalCount(data.data.jobs.totalCount);

          // lookup what's been completed
          const customerIdsFound = (data.data.jobs.edges.node || []).map((n: any) => n.customerId);
          if (customerIdsFound.length > 0) {
            let whereFilterRecentlyCompleted: any = getFilter(filterKey, { ...filter, viewPortRecentlyCompleted: true, viewPortCustomerIdsFound: customerIdsFound }, state.profile);
            const dataRecentlyCompletd = await client.query({
              query: GET_JOBS_RECENTLY_COMPLETED,
              variables: {
                limit: 999,
                offset: 0,
                where: whereFilterRecentlyCompleted
              },
              fetchPolicy: 'no-cache'
            });

            setJobsRecentlyCompleted(dataRecentlyCompletd?.data?.jobs?.edges?.node || []);
          }

          DOMHelper.scrollTop(window as any, 0);
        } catch (err: any) {
          throw new Error(err);
        } finally {
          setStatus(STATUS.LOADED);
        }
      })();
    }
  }, [sort, offset, filter, modifiedAt, recordsPerPage, showMyBookings, match?.params?.view, window.location.pathname, state.modifiedAt]);

  const handleJobUpdate = async (jobGuid: string, data: any) => {
    let processUpdate = false;
    const update: any = {};

    if ((data.completedServices || []).length > 0 || (data.siteChecks || []).length > 0) {
      data.status = 'completed';
    }

    try {
      /*
      if (data.status) {
        const job = jobs.find((j: any) => j.guid === jobGuid);

        // onetime, completed and maintenance pop up a window to select date
        if (['onetime', 'ondemand'].includes(job?.recurrence) && data.status === 'completed' && !['roofing__59634106__1710128534', 'snow_removal', 'construction', 'christmas_lights'].includes(data.applicationGroup) && data.forceUpdate) {
          setJobs(jobs.map((j: any) => {
            if (j.guid === jobGuid) {
              j.status = data.status;
            }

            return j;
          }));
        } else {
          processUpdate = true;

          setJobs(jobs.map((j: any) => {
            if (j.guid === jobGuid) {
              update.status = data.status;
              j.status = update.status;

              if (data.status !== 'completed') {
                data.completedServices = [];
                j.completedServices = [];
              }
            }

            return j;
          }));
        }
      }
      */

      if (data.completedServices || data.perCostTypeCost || data.timeOnSite || data.timeFromSite || data.peopleOnSite || data.hasOwnProperty('notes')
        || data.hasOwnProperty('deIcerValue') || data.siteChecks || data.hasOwnProperty('gravelAmount') || data.hasOwnProperty('gravelBins') || data.startDate || data.endDate
        || data.hasOwnProperty('numberOfTrees') || data.hasOwnProperty('numberOfShrubs') || data.hasOwnProperty('trackingFlag') || data.hasOwnProperty('status')) {
        processUpdate = true;

        setJobs(jobs.map((j: any) => {
          if (j.guid === jobGuid) {

            if (data.status) {
              // onetime, completed and maintenance pop up a window to select date
              if (['onetime', 'ondemand'].includes(j?.recurrence) && data.status === 'completed' && !['roofing__59634106__1710128534', 'snow_removal', 'construction', 'christmas_lights'].includes(data.applicationGroup) && data.forceUpdate) {
                j.status = data.status;
              } else {
                update.status = data.status;
                j.status = update.status;

                if (data.status !== 'completed') {
                  data.completedServices = [];
                  j.completedServices = [];
                }
              }
            }

            if (data.siteChecks) {
              update.siteChecks = data.siteChecks;
              j.siteChecks = [...update.siteChecks];
            }

            if (data.completedServices) {
              update.completedServices = data.completedServices;
              j.completedServices = [...update.completedServices];
            }

            if (data.hasOwnProperty('trackingFlag')) {
              update.trackingFlag = data.trackingFlag;
              j.trackingFlag = data.trackingFlag;
            }

            // update custom fields
            ['numberOfTrees', 'numberOfShrubs'].forEach((u) => {
              update.customFields = { ...(update.customFields || {}), [u]: data[u] };
            });

            // update times
            ['timeOnSite', 'timeFromSite', 'peopleOnSite', 'perCostTypeCost', 'deIcerValue', 'gravelAmount', 'gravelBins', 'numberOfTrees', 'numberOfShrubs']
              .forEach((u) => {
                if (data.hasOwnProperty(u)) {
                  // if (!j.props) {
                  //   j.props = {};
                  // }

                  update[u] = data.hasOwnProperty(u) ? data[u] : j.props[snakeCase(u)];
                }
              });

            // notes
            if (data.hasOwnProperty('notes')) {
              j.notes = data.notes;
              update.notes = data.notes;
            }

            if (data.hasOwnProperty('startDate')) {
              if (typeof data.startDate === 'string') {
                j.startDate = data.startDate;
              } else {
                j.startDate = data.startDate.toISOString();
              }
              update.startDate = data.startDate;
            }

            if (data.hasOwnProperty('endDate')) {
              if (typeof data.endDate === 'string') {
                j.endDate = data.endDate;
              } else {
                j.endDate = data.endDate.toISOString();
              }
              update.endDate = data.endDate;
            }
          }

          return j;
        }) as any);
      }


      if (processUpdate) {
        const res = await client.mutate({
          mutation: updateJob,
          variables: Object.assign(update, { guid: jobGuid })
        });

        if (!data.services) {
          if (res.data.updateJob.success) {
            toaster.push(
              <Message type="success" showIcon closable>{res.data.updateJob.message}</Message>
            );
            setJobs(jobs.map((j: any) => j.guid === res.data.updateJob.result.guid ? { ...j, ...res.data.updateJob.result } : j));
          } else {
            toaster.push(
              <Message type="error" showIcon closable>{res.data.updateJob.message}</Message>
            );
          }
        }
        setActiveJob(undefined);
      }
    } catch (err) {
      showError(err);
    } finally {
      handleHideDrawer();
    }
  }

  const handleJobDoneUpdate = (guid: string) => {
    const job: any = jobs.find((j: any) => j.guid === guid);
    if (['onetime', 'ondemand'].includes(job?.recurrence) && job?.status === 'completed' && !['roofing__59634106__1710128534', 'snow_removal', 'construction', 'christmas_lights'].includes(job?.applicationGroup)) {
      setActiveDrawer('completedAt');
      setActiveJob(job);
    }
  }

  const handleJobReschedule = (guid: string, date: Date | Date[]) => {
    (async function jobReschedule() {
      let job: any;

      try {
        setJobs(jobs.map((j: any) => {
          if (j.guid === guid) {
            job = j;

            if (Array.isArray(date)) {
              j.startDate = date[0].toISOString();
              j.endDate = date[1].toISOString();
            } else {
              j.startDate = j.endDate = date.toISOString();
            }
          }

          return j;
        }));
        const res: any = await rescheduleJob(client, guid, job.startDate, job.endDate);
        toaster.push(
          <Message type="success" showIcon closable>{res.data.rescheduleJob.message}</Message>
        );
      } catch (err) {
        showError(err);
      }
    })();
  }

  const handleShowDrawer = (jobGuid: string, drawer: string) => {
    const job: any = jobs.find((j: any) => j.guid === jobGuid);
    setActiveJob({ ...job });
    setActiveDrawer(drawer);
  }

  const handleHideDrawer = () => {
    setActiveDrawer(undefined);
    setActiveJob(undefined);
  }

  const handleSummaryHide = () => {
    setRecord(undefined);
  }

  const handleSummaryShow = (customerId: number, workOrderGuid: string) => {
    setRecord({
      customerId,
      workOrderGuid
    });
  }

  const handleClearJobs = () => {
    setJobs([]);
  }

  const handleTimeEntryRemove = async (jobGuid: string, timeCardGuid: string) => {
    setJobs(jobs.map((j: any) => {
      if (j.guid === jobGuid) {
        j.timeCard = j.timeCard.filter((t: any) => t.guid !== timeCardGuid);
      }

      return j;
    }));

    try {
      const response: any = await client.mutate({
        mutation: gql`
            mutation deleteTimeCard($guid: ID!) {
              deleteTimeCard(guid: $guid) {
                code
                success
                message
              }
            }
          `,
        variables: {
          guid: timeCardGuid
        }
      });

      if (response.data.deleteTimeCard.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.deleteTimeCard.message}</Message>
        );
      } else {
        showError(response);
      }
    } catch (err) {
      showError(err);
    }
  }

  const handleTimeEntryAdd = (jobGuid: string) => {
    setJobs(jobs.map((j: any) => {
      if (j.guid === jobGuid) {
        const userGuid = state?.userIdMap?.[j.userId]?.guid;

        j.timeCard = [...(j.timeCard || []), {
          guid: uuidv4(),
          jobGuid,
          userGuid
        }]
      }

      return j;
    }));
  }

  const handleTimeEntryUpdate = async (guid: string, input: any) => {
    try {
      const response: any = await client.mutate({
        mutation: gql`
          mutation upsertTimeCard($input: UpsertTimeCardInput) {
            upsertTimeCard(input: $input) {
              code
              success
              message
              result
            }
          }
        `,
        variables: {
          input: omit({ ...input, guid }, ['timeSpent'])
        }
      });

      if (response.data.upsertTimeCard.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.upsertTimeCard.message}</Message>
        );
      } else {
        showError(response);
      }
    } catch (err) {
      showError(err);
    }
  }

  const handleWorkOrderSelect = (guid: string | undefined, startEndDate: [Date, Date] | undefined) => {
    setWorkOrderEvent({
      show: true,
      startEndDate,
      action: guid === undefined ? 'add' : 'edit',
      guid
    });
  }

  const handleJobRefresh = (guid: string) => {
    getJob({ variables: { limit: 1, offset: 0, where: { guid: { is: guid } } } });
  }

  return (
    <Fragment>
      <Filter
        tableDataRef={tableDataRef}
        id={filterKey}
        showJobStatus={!isRole(ROLE.CLIENT, ROLE.CLEANER)}
        onChange={(filter: any, order: any) => {
          setFilter(filter);
          setOffset(0);
        }}
        dateRangeCleanable={window.location.pathname.includes('on-demand')}
        clearJobs={handleClearJobs}
        showMultipleCrewFilter={true}
        showGroupFilter={!isRole(ROLE.CLIENT, ROLE.CLEANER)}
        showTextFilter={!isRole(ROLE.CLIENT, ROLE.CLEANER)}
        showCustomerLabelFilter={!isRole(ROLE.CLIENT, ROLE.CLEANER) && IS_BUGABOO}
        showCompletedByFilter={true}
        showTrackingFlagFilter={true}
      >
        {(IS_KALADI_KITCHENS && isRole(ROLE.CLIENT, ROLE.CLEANER)) &&
          <Col md={3}>
            <Checkbox checked={showMyBookings} onClick={() => setShowMyBookings(!showMyBookings)}>Show My Booking Only</Checkbox>
          </Col>
        }
        {!IS_KALADI_KITCHENS &&
          <Col md={3} xs={24} style={{ float: 'right', textAlign: 'right' }}>
            <MapToggleField />
            <ColumnChooserField filterKey={filterKey} />
            <ExportField />
          </Col>
        }
      </Filter>

      {(IS_BUGABOO && !confirmedOutsideDateRange && (isRole(ROLE.FRANCHISE, ROLE.WORKER) && ((format(params.range[0], FORMAT.ISO_DATE) !== format(new Date(), FORMAT.ISO_DATE))
        || (format(params.range[1], FORMAT.ISO_DATE) !== format(new Date(), FORMAT.ISO_DATE))))) &&
        <Modal open className='danger'>
          <Modal.Header closeButton={false}>
            <Modal.Title>Date Outside of Today</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Dates selected outside of current day. If you are completing jobs ensure that the date of completion is correct.
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" appearance="primary" onClick={() => {
              store.session.remove('jobs-range-filter');
              store.session.remove('jobs-range-filter-active');
              window.location.href = `/app/${getEnv()}/jobs/list`;
            }}>
              Go To Today
            </Button>
            <Button size="sm" appearance="subtle" onClick={() => setConfirmedOutsideDateRange(true)}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      }

      {(isRole(ROLE.FRANCHISE, ROLE.WORKER) && ((format(params.range[0], FORMAT.ISO_DATE) !== format(new Date(), FORMAT.ISO_DATE))
        || (format(params.range[1], FORMAT.ISO_DATE) !== format(new Date(), FORMAT.ISO_DATE)))) &&
        <Message
          className="mb-10"
          type="error">Dates selected outside of today. If you are completing jobs ensure that the date of completion is correct, <a href={`/app/${getEnv()}/jobs/list`} onClick={(e: any) => {
            e.preventDefault();
            store.session.remove('jobs-range-filter');
            store.session.remove('jobs-range-filter-active');
            window.location.href = `/app/${getEnv()}/jobs/list`;
          }}>click here</a> to go to current date.
        </Message>
      }

      <JobsReport
        reports={reports}
        filter={filter}
        setFilter={setFilter}
      />

      {/**
       * need to slice here because coming back from calendar has all the jobs
       * jobsCompletionReport.data.jobsCompletionReport
       */}
      {isMobileOnly &&
        <ViewMobile
          loading={status === STATUS.LOADING}
          jobs={jobs}
          jobsRecentlyCompleted={jobsRecentlyCompleted}
          handleJobUpdate={handleJobUpdate}
          handleJobDoneUpdate={handleJobDoneUpdate}
          handleShowDrawer={handleShowDrawer}
          handleJobReschedule={handleJobReschedule}
          handleSummaryShow={handleSummaryShow}
          handleClockInOutShow={(mode: string, job: any) => {
            setClockInOutMode(mode);
            setClockInOutJob(job);
          }}
          handleWorkOrderSelect={handleWorkOrderSelect}
          handleJobRefresh={handleJobRefresh}
        />
      }

      {isBrowser &&
        <ViewDesktop
          sort={sort}
          loading={status === STATUS.LOADING}
          jobs={jobs}
          jobsRecentlyCompleted={jobsRecentlyCompleted}
          handleJobUpdate={handleJobUpdate}
          handleJobDoneUpdate={handleJobDoneUpdate}
          handleShowDrawer={handleShowDrawer}
          handleJobReschedule={handleJobReschedule}
          handleSummaryShow={handleSummaryShow}
          handleJobRefresh={handleJobRefresh}
          handleTimeEntryAdd={handleTimeEntryAdd}
          handleTimeEntryRemove={handleTimeEntryRemove}
          handleTimeEntryUpdate={handleTimeEntryUpdate}
          handleClockInOutShow={(mode: string, job: any) => {
            setClockInOutMode(mode);
            setClockInOutJob(job);
          }}
          handleWorkOrderSelect={handleWorkOrderSelect}
          onSort={(sortColumn: string) => setSort({
            ...sort,
            sortColumn: getSortType(sort.sortType) === undefined ? undefined : sortColumn,
            sortType: getSortType(sort.sortType)
          })}
          tableDataRef={tableDataRef}
        />
      }

      {(status === STATUS.LOADED && window.location.pathname.indexOf('/map') === -1) &&
        <Pagination
          offset={offset < 0 ? 0 : offset}
          totalCount={totalCount}
          recordsPerPage={recordsPerPage}
          onChangeOffset={setOffset}
          onChangeLength={setRecordsPerPage}
        />
      }

      <PhotoUploader
        show={activeJob?.guid && activeDrawer === 'photos'}
        title={activeJob?.customer?.displayName}
        emails={(activeJob?.customer?.email || '').split(';')}
        jobGuid={activeJob?.guid}
        handleHideDrawer={() => {
          handleJobRefresh(activeJob?.guid);
          handleHideDrawer();
          setActiveJob(undefined);
        }}
        onChange={(data: any) => { }}
      />

      <Notes
        show={activeJob?.guid && activeDrawer === 'notes'}
        handleHideDrawer={handleHideDrawer}
        guid={activeJob?.guid}
        notes={activeJob?.notes}
        title={activeJob?.customer?.displayName}
        handleJobUpdate={handleJobUpdate}
      />

      <ClockInOut
        show={clockInOutJob !== undefined}
        mode={clockInOutMode}
        job={clockInOutJob}
        onHide={() => {
          setClockInOutJob(undefined);
          setClockInOutMode(undefined);
        }}
        onUpdate={() => {
          setModifiedAt(new Date().toISOString());
        }}
      />

      {(activeDrawer === 'completedAt' && activeJob) &&
        <Modal backdrop="static" open onClose={() => setActiveDrawer(undefined)} size={'xs'} full={isMobileOnly}>
          <Modal.Header><Modal.Title>Select Date Completed</Modal.Title></Modal.Header>
          <Modal.Body>
            <Message type='info' className='mb-12'>This step must be completed in order to complete the job.</Message>

            <div className='mb-4'>
              {activeJob?.customer?.displayName} {INTL.JOB.toLowerCase()} requires date completed:
            </div>
            <DatePicker
              menuStyle={{ zIndex: 1051 }}
              oneTap
              placement='bottomEnd'
              cleanable={false}
              renderValue={(date: Date) => format(date, FORMAT.MONTH_DATE)}
              value={parseISO(activeJob?.startDate)}
              onSelect={(date: Date) => {
                setActiveJob({ ...activeJob, startDate: date, endDate: date });
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar>
              <Button onClick={() => {
                handleJobUpdate(activeJob.guid, {
                  applicationGroup: activeJob.applicationGroup,
                  startDate: activeJob.startDate,
                  endDate: activeJob.endDate,
                  status: 'completed',
                  forceUpdate: true
                });
                setActiveDrawer(undefined);
                setActiveJob(undefined);
              }} appearance="primary">OK</Button>
              <Button onClick={() => {
                setActiveDrawer(undefined);
                setActiveJob(undefined);
              }} appearance="subtle">Cancel</Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Modal>
      }

    </Fragment>
  );
};

export default JobsList;
